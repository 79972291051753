/** Importing Static Images */

import Blank_Picture from './Images/Blank_Picture.png';
import gallery from './gallery.jpg';
import llogoSmall from './logo/logo.svg';
import llogoFull from './logo/logoFull.png';
import llogoRedSmall from './logo/logoRed.svg';
import llogoRedWhite from './logo/logoRedWhite.svg';
import logoRound from './logo/logoRound.png';
import llogoWhiteFull from './logo/logoWhiteFull.svg';
import youtube_logo from './yt.png';

let logoSmall = llogoSmall;
let logoFull = llogoFull;
let logoRedSmall = llogoRedSmall;
let logoRedWhite = llogoRedWhite;
let logoWhiteFull = llogoWhiteFull;

// if (
//   window.location.hostname === 'www.stage-mrz-marooze.com' ||
//   window.location.hostname === 'localhost'
// ) {
//   logoSmall = staging;
//   logoFull = staging;
//   logoRedSmall = staging;
//   logoRedWhite = staging;
//   logoWhiteFull = staging;
// }
export {
  Blank_Picture,
  gallery,
  logoFull,
  logoRedSmall,
  logoRedWhite,
  logoRound,
  logoSmall,
  logoWhiteFull,
  youtube_logo,
};
