import React from 'react';
import { activityDetailTraslate } from '../../Sections/SectionLatestActivitiesTable/SectionLatestActivities';
import SmallCard from '../../components/Cards/NewCards/SmallCard';
import useTranslate from '../ContextHooks/useTranslate';

interface Props {
  activity: any;
  closeToast?: () => {};
}

/**
 * Language Notification to be used with any changing Language
 *
 * @category Components
 * @subcategory Notification
 *
 * @component
 */
const ComponentNotifier: React.FC<Props> = (props) => {
  const { t } = useTranslate();
  return (
    <div className='min-w-80'>
      <SmallCard
        opacity
        avatar={props.activity?.activityBy?.avatar}
        line1={activityDetailTraslate(props.activity.detail, t)}
        onlyLine1
        link={'/profile/' + props.activity?.activityBy?.slug}
        handleClose={() => {
          props.closeToast && props.closeToast();
        }}
      />
    </div>
  );
};

export default React.memo(ComponentNotifier);
