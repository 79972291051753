import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import useTranslate from '../../../Context/ContextHooks/useTranslate';
import { cn } from '../../../Helpers/cn';
import TooltipHOC from '../../../hooks/HOC/TooltipHOC';

interface Props {
  label?: string;
  getValue?: Function;
  value?: string;
  tooltip?: string;

  textarea?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
}
/**
 *  Input Text which supports label and input field to be on 2 lines one after another (commanly used in Application)
 *
 * @category Components
 * @subcategory Form Element
 *
 * @component
 */
const TextArea: React.FC<Props> = (props) => {
  const [value, setValue] = useState('');
  const { t } = useTranslate();
  const handleChange = (event: React.ChangeEvent) => {
    let element = event.currentTarget as HTMLTextAreaElement;
    let value = element.value;

    if (props.getValue) {
      props.getValue(value);
    }
    setValue(value);
  };

  useEffect(() => {
    if (props.value) {
      if (value !== props.value) setValue(props.value);
    }
    if (props.value === '') {
      if (value !== props.value) setValue(props.value);
    }
  }, [props.value, value]);

  return (
    <fieldset
      className={cn(
        'relative w-full rounded-md border-2  border-solid border-[#555]  text-white placeholder-white ',
        {
          'hover:border-gray-300': !props.textarea?.disabled,
        }
      )}
    >
      {props.label && (
        <legend className='float-none mb-0 ml-1 w-auto px-1 text-sm'>
          {t(props.label)}
          {!props.textarea?.disabled && props.textarea?.required && (
            <span className='text-red-600'>*</span>
          )}
          {props.tooltip && (
            <TooltipHOC message={props.tooltip}>
              <FontAwesomeIcon
                icon={icon({ name: 'info-circle', style: 'light' })}
                size='lg'
                className='ml-1'
              />
            </TooltipHOC>
          )}
        </legend>
      )}

      <textarea
        {...props.textarea}
        className='w-full resize-none bg-transparent px-2 pb-1 focus:outline-none disabled:opacity-85'
        onChange={(e) => handleChange(e)}
        value={value}
      />
    </fieldset>
  );
};

export default React.memo(TextArea);
