/**
 * Section Header to be used in Pages or in Other Section Only
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense } from 'react';
import { BallTriangle } from 'react-loader-spinner';

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ErrorBoundary } from 'react-error-boundary';
import usePermission from '../Context/ContextHooks/usePermission';
import useTranslate from '../Context/ContextHooks/useTranslate';
import { cn } from '../Helpers/cn';
import Fallback from './Fallback';
import NewPopover from './Tooltip';
// import { Prompt } from 'react-router-dom';

interface Props {
  title?: string;
  children: React.ReactNode;

  editable?: boolean;
  noneditable?: boolean;

  popoverPre?: string;

  className?: string;
  tilteClassName?: string;

  handleEditClick?: Function;
  cancelEdit?: Function;

  handleCopy?: Function;
  handleFolderPlus?: Function;
  handleUploadClick?: Function;
  handleFileEdit?: Function;
  handleTrashClick?: Function;
  handleBoxBallotClick?: Function;
  handleTimes?: Function;

  handleFileAdd?: Function;
  permissionFileAdd?: boolean | null;

  handleFile?: Function;
  handleSave?: Function;
  handleStar?: Function;
  isStar?: boolean;
  handleUserPlus?: Function;
  handlePaperPlane?: Function;
  handlePaperClip?: Function;
  handleArrowRotateLeft?: Function;
  isCardsBlack?: boolean;
  handleCardsBlank?: Function;
  handleCheckSquare?: Function;
  handleFileCSV?: Function;
  handleUndoClick?: Function;
  handleSearchClick?: Function;
  handleUserFriend?: Function;
  handleUserClick?: Function;
  handlePlus?: Function;
  handleLaugh?: Function;
  handleExclamationTriangle?: Function;
  handleCC_Click?: Function;
  handleAddressCard?: Function;

  onClick?: Function;
  handleLanguage?: Function;
}

/**
 *  Section Header to be used in Pages or in Other Section Only
 * @category Section
 * @subcategory Event

 *
 * @component
 */

const SectionHeader: React.FC<Props> = (props) => {
  const { t } = useTranslate();
  const { isVisitor } = usePermission();

  return (
    <div
      className={cn(
        'border border-light_grey bg-primary p-4 text-white',
        { 'border-[#22c55d]': props.editable },
        props.className
      )}
    >
      <div className=' flex justify-between'>
        {props.title ? (
          <h4
            className={cn(
              ' mb-3 line-clamp-2 text-xl font-extralight',
              props.tilteClassName
            )}
          >
            {t(props.title)}
          </h4>
        ) : (
          <div />
        )}

        <div className='flex gap-2 text-white/60 [&_svg]:cursor-pointer'>
          <>
            {(props.editable || props.noneditable) && (
              <>
                {props.handleSearchClick && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'search', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleSearchClick();
                    }}
                    size='xl'
                  />
                )}
                {props.handleUserClick && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'user', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleUserClick();
                    }}
                    size='xl'
                  />
                )}
                {!isVisitor() && props.handleCardsBlank && (
                  <NewPopover
                    title={props.popoverPre + '-POPOVER-CARDBLANK-TITLE'}
                    text={props.popoverPre + '-POPOVER-CARDBLANK-TEXT'}
                    link={props.popoverPre + '-POPOVER-CARDBLANK-LINK'}
                    popoverdisabled={!props.popoverPre}
                  >
                    <FontAwesomeIcon
                      icon={
                        props.isCardsBlack
                          ? icon({ name: 'cards-blank', style: 'solid' })
                          : icon({ name: 'cards-blank', style: 'light' })
                      }
                      className={props.isCardsBlack ? 'text-[#00d26a]' : ''}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleCardsBlank();
                      }}
                      size='xl'
                    />{' '}
                  </NewPopover>
                )}
                {props.handleBoxBallotClick && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'box-ballot', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleBoxBallotClick();
                    }}
                    size='xl'
                  />
                )}
                {props.handleFileCSV && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'file-csv', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleFileCSV();
                    }}
                    size='xl'
                  />
                )}
                {props.handlePaperPlane && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'paper-plane', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handlePaperPlane();
                    }}
                    size='xl'
                  />
                )}
                {props.handleStar && (
                  <NewPopover
                    title={props.popoverPre + '-POPOVER-STAR-TITLE'}
                    text={props.popoverPre + '-POPOVER-STAR-TEXT'}
                    link={props.popoverPre + '-POPOVER-STAR-LINK'}
                    popoverdisabled={!props.popoverPre}
                  >
                    <FontAwesomeIcon
                      icon={
                        props.isStar
                          ? icon({ name: 'star', style: 'solid' })
                          : icon({ name: 'star', style: 'light' })
                      }
                      className={props.isStar ? 'text-gold' : ''}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleStar();
                      }}
                      size='xl'
                    />{' '}
                  </NewPopover>
                )}
                {props.handleCheckSquare && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'check-square', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleCheckSquare();
                    }}
                    size='xl'
                  />
                )}
                {props.handleUserFriend && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'user-friends', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleUserFriend();
                    }}
                    size='xl'
                  />
                )}
                {props.handleTimes && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'times', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleTimes();
                    }}
                    size='xl'
                  />
                )}
                {props.handleUserPlus && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'user-plus', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleUserPlus();
                    }}
                    size='xl'
                  />
                )}
                {props.handleExclamationTriangle && (
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'exclamation-triangle',
                      style: 'light',
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleExclamationTriangle();
                    }}
                    size='xl'
                  />
                )}
                {props.handleLaugh && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'laugh', style: 'light' })}
                    id='emojis-btn'
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleLaugh();
                    }}
                    size='xl'
                  />
                )}
                {props.handlePaperClip && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'paperclip', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handlePaperClip();
                    }}
                    size='xl'
                  />
                )}

                {props.handleFolderPlus && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'folder-plus', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleFolderPlus();
                    }}
                    size='xl'
                  />
                )}
                {props.handleFileAdd && props.permissionFileAdd !== false && (
                  <NewPopover
                    title={props.popoverPre + '-POPOVER-FILE-TITLE'}
                    text={props.popoverPre + '-POPOVER-FILE-TEXT'}
                    link={props.popoverPre + '-POPOVER-FILE-LINK'}
                    popoverdisabled={!props.popoverPre}
                  >
                    <FontAwesomeIcon
                      icon={icon({ name: 'file-plus', style: 'light' })}
                      className={
                        props.permissionFileAdd === null ? 'disabled' : ''
                      }
                      onClick={(e) => {
                        if (props.permissionFileAdd !== null) {
                          e.stopPropagation();
                          props.handleFileAdd(e);
                        }
                      }}
                      size='xl'
                    />
                  </NewPopover>
                )}
                {props.handleCopy && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'copy', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleCopy();
                    }}
                    size='xl'
                  />
                )}

                {props.handleUploadClick && (
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'cloud-arrow-up',
                      style: 'light',
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleUploadClick();
                    }}
                    size='xl'
                  />
                )}
                {props.handleFileEdit && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'edit', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleFileEdit();
                    }}
                    size='xl'
                  />
                )}
                {props.handleArrowRotateLeft && (
                  <NewPopover
                    title={props.popoverPre + '-POPOVER-ARROWLEFT-TITLE'}
                    text={props.popoverPre + '-POPOVER-ARROWLEFT-TEXT'}
                    link={props.popoverPre + '-POPOVER-ARROWLEFT-LINK'}
                    popoverdisabled={!props.popoverPre}
                  >
                    <FontAwesomeIcon
                      icon={icon({
                        name: 'arrow-rotate-left',
                        style: 'light',
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleArrowRotateLeft();
                      }}
                      size='xl'
                    />
                  </NewPopover>
                )}
                {props.handleFile && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'file', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleFile();
                    }}
                    size='xl'
                  />
                )}
                {props.handleSave && (
                  <NewPopover
                    title={props.popoverPre + '-POPOVER-SAVE-TITLE'}
                    text={props.popoverPre + '-POPOVER-SAVE-TEXT'}
                    link={props.popoverPre + '-POPOVER-SAVE-LINK'}
                    popoverdisabled={!props.popoverPre}
                  >
                    <FontAwesomeIcon
                      icon={icon({ name: 'save', style: 'light' })}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleSave();
                      }}
                      size='xl'
                    />
                  </NewPopover>
                )}
                {props.handlePlus && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'plus', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handlePlus();
                    }}
                    size='xl'
                  />
                )}
                {props.handleUndoClick && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'undo', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleUndoClick();
                    }}
                    size='xl'
                  />
                )}
                {props.handleTrashClick && (
                  <NewPopover
                    title={props.popoverPre + '-POPOVER-TRASH-TITLE'}
                    text={props.popoverPre + '-POPOVER-TRASH-TEXT'}
                    link={props.popoverPre + '-POPOVER-TRASH-LINK'}
                    popoverdisabled={!props.popoverPre}
                  >
                    <FontAwesomeIcon
                      icon={icon({ name: 'trash-alt', style: 'light' })}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleTrashClick();
                      }}
                      size='xl'
                    />{' '}
                  </NewPopover>
                )}
                {props.handleAddressCard && (
                  <FontAwesomeIcon
                    icon={icon({ name: 'address-card', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleAddressCard();
                    }}
                    size='xl'
                  />
                )}
                {props.handleCC_Click && (
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'credit-card-front',
                      style: 'light',
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleCC_Click();
                    }}
                    size='xl'
                  />
                )}
              </>
            )}
            {props.handleLanguage && (
              <NewPopover
                title={'SECTION-POPOVER-TRANSLATE-TITLE'}
                text={'SECTION-POPOVER-TRANSLATE-TEXT'}
                link={'SECTION-POPOVER-TRANSLATE-LINK'}
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'language', style: 'light' })}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.handleLanguage();
                  }}
                  size='xl'
                />
              </NewPopover>
            )}

            {!props.editable && props.handleEditClick && (
              <NewPopover
                title={'SECTION-POPOVER-EDIT-TITLE'}
                text={'SECTION-POPOVER-EDIT-TEXT'}
                link={'SECTION-POPOVER-EDIT-LINK'}
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'edit', style: 'light' })}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.handleEditClick && props.handleEditClick();
                  }}
                  size='xl'
                />
              </NewPopover>
            )}
            {props.editable && (
              <>
                <NewPopover
                  title={'SECTION-POPOVER-CANCEL-TITLE'}
                  text={'SECTION-POPOVER-CANCEL-TEXT'}
                  link={'SECTION-POPOVER-CANCEL-LINK'}
                >
                  <FontAwesomeIcon
                    icon={icon({ name: 'save', style: 'light' })}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleEditClick && props.handleEditClick();
                    }}
                    size='xl'
                  />
                </NewPopover>
                {props.cancelEdit && (
                  <NewPopover
                    title={'SECTION-POPOVER-CANCEL-TITLE'}
                    text={'SECTION-POPOVER-CANCEL-TEXT'}
                    link={'SECTION-POPOVER-CANCEL-LINK'}
                  >
                    <FontAwesomeIcon
                      icon={icon({ name: 'times', style: 'light' })}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.cancelEdit();
                      }}
                      size='xl'
                    />
                  </NewPopover>
                )}
              </>
            )}
          </>
        </div>
      </div>

      <ErrorBoundary FallbackComponent={Fallback}>
        <Suspense
          fallback={
            <div className='centre my-5'>
              <BallTriangle color='#FFFFFF' height={150} width={150} />
            </div>
          }
        >
          {props.children}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

SectionHeader.defaultProps = {
  editable: false,
};

export default React.memo(SectionHeader);
