import React, { useEffect, useMemo, useState } from 'react';
import useTranslate from '../../Context/ContextHooks/useTranslate';
import PremiumHOC from '../../hooks/HOC/PremiumHOC';
import SectionHeader from '../SectionHeader/sectionHeader';

import { DateTime } from 'luxon';
import { getMyActivity } from '../../BackendConnection/Activity';
import GeneralTable from '../../components/Table/GeneralTable';
import Input from '../../new-design/Components/FormElements/Input';
import Select from '../../new-design/Components/FormElements/Select/Select';

interface Props {}

const SectionLatestActivities: React.FC<Props> = () => {
  // const [tableData, setTableData] = useState<any[]>([]);
  const [activity, setActivity] = useState<any[]>([]);

  useEffect(() => {
    const getLatestProfile = async () => {
      const profiles = await getMyActivity({});

      setActivity(profiles?.data);
    };
    getLatestProfile();
  }, []);

  const { t } = useTranslate();

  const [filter, setFilter] = useState({
    activity: { label: 'DASHBOARD-ACTIVITY-TYPE-ALL', value: '' },
    detail: '',
  });

  const setFilt = (key: string, value: string) => {
    setFilter({ ...filter, [key]: value });
  };

  const columns = [
    {
      Header: t('DASHBOARD-ACTIVITY-TABLE-DATE'),
      accessor: 'date',
      Cell: ({ value }) => {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
      },
    },

    {
      Header: t('DASHBOARD-ACTIVITY-TABLE-TYPE'),
      accessor: 'activity',
      Cell: ({ value }) => {
        return t('DASHBOARD-ACTIVITY-TYPE-' + value);
      },
    },
    {
      Header: t('DASHBOARD-ACTIVITY-TABLE-DETAIL'),
      accessor: 'detail',
      width: 200,
    },
  ];

  const tableData = useMemo(() => {
    return (
      activity &&
      activity.map((activityObj: any) => {
        return {
          detail: activityDetailTraslate(activityObj.detail, t),
          activity: activityObj.activity,
          activityBy: activityObj.activityBy,
          date: activityObj.createdAt,
        };
      })
    );
  }, [activity, t]);

  return (
    <SectionHeader title='DASHBOARD-SECTION-ACTIVITY'>
      <div className='grid grid-cols-4 gap-2'>
        <div className='col-span-4 md:col-span-1'>
          <PremiumHOC
            onNull={
              <Input
                label={`DASHBOARD-ACTIVITY-TITLE`}
                input={{ disabled: true }}
                value={filter.detail}
                getValue={() => {}}
              />
            }
          >
            <Input
              label={`DASHBOARD-ACTIVITY-TITLE`}
              value={filter.detail}
              getValue={(e: string) => setFilt('detail', e)}
            />
          </PremiumHOC>
        </div>

        <div className='col-span-4 md:col-span-1'>
          <PremiumHOC
            onNull={
              <Select
                label={`DASHBOARD-ACTIVITY-TYPE`}
                withOptions
                disabled
                options={[
                  { label: 'DASHBOARD-ACTIVITY-TYPE-ALL', VALUE: '' },
                  {
                    label: 'DASHBOARD-ACTIVITY-TYPE-CONNECTION',
                    value: 'CONNECTION',
                  },
                  // {
                  //   label: 'DASHBOARD-ACTIVITY-TYPE-COMMENT',
                  //   value: 'COMMENT',
                  // },
                  // { label: 'DASHBOARD-ACTIVITY-TYPE-LIKE', VALUE: 'LIKE' },
                  {
                    label: 'DASHBOARD-ACTIVITY-TYPE-BUY_PRODUCT',
                    value: 'BUY_PRODUCT',
                  },
                  {
                    label: 'DASHBOARD-ACTIVITY-TYPE-MESSAGE',
                    value: 'MESSAGE',
                  },
                  {
                    label: 'DASHBOARD-ACTIVITY-TYPE-PROJECT',
                    value: 'PROJECT',
                  },
                  {
                    label: 'DASHBOARD-ACTIVITY-TYPE-QUESTION',
                    value: 'QUESTION',
                  },
                  { label: 'DASHBOARD-ACTIVITY-TYPE-POLL', VALUE: 'POLL' },
                  {
                    label: 'DASHBOARD-ACTIVITY-TYPE-OBSERVE',
                    value: 'OBSERVE',
                  },
                  {
                    label: 'DASHBOARD-ACTIVITY-TYPE-GENERAL',
                    value: 'GENERAL',
                  },
                ]}
                value={filter.activity}
                getValue={() => {}}
              />
            }
          >
            <Select
              label={`DASHBOARD-ACTIVITY-TYPE`}
              pad0
              withOptions
              options={[
                { label: 'DASHBOARD-ACTIVITY-TYPE-ALL', value: '' },
                {
                  label: 'DASHBOARD-ACTIVITY-TYPE-CONNECTION',
                  value: 'CONNECTION',
                },
                // {
                //   label: 'DASHBOARD-ACTIVITY-TYPE-COMMENT',
                //   value: 'COMMENT',
                // },
                // { label: 'DASHBOARD-ACTIVITY-TYPE-LIKE', value: 'LIKE' },
                {
                  label: 'DASHBOARD-ACTIVITY-TYPE-BUY_PRODUCT',
                  value: 'BUY_PRODUCT',
                },
                {
                  label: 'DASHBOARD-ACTIVITY-TYPE-MESSAGE',
                  value: 'MESSAGE',
                },
                {
                  label: 'DASHBOARD-ACTIVITY-TYPE-APPROVAL',
                  value: 'APPROVAL',
                },
                {
                  label: 'DASHBOARD-ACTIVITY-TYPE-PROJECT',
                  value: 'PROJECT',
                },

                {
                  label: 'DASHBOARD-ACTIVITY-TYPE-OBSERVE',
                  value: 'OBSERVE',
                },
                {
                  label: 'DASHBOARD-ACTIVITY-TYPE-GENERAL',
                  value: 'GENERAL',
                },
              ]}
              value={filter.activity}
              getValue={(e: string) => setFilt('activity', e)}
            />
          </PremiumHOC>
        </div>

        <div className='col-span-4'>
          <GeneralTable
            column={columns}
            data={tableData}
            filter={{ ...filter, activity: filter.activity.value }}
            isFilterable
            isSortable
          />
        </div>
      </div>
    </SectionHeader>
  );
};

export default React.memo(SectionLatestActivities);

export const activityDetailTraslate = (detail: string, t) => {
  const tranaslated = detail
    .split(' ')
    .map((i) => t(i))
    .join(' ');

  return tranaslated;
};
