import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import useTranslate from '../../../../Context/ContextHooks/useTranslate';
import { cn } from '../../../../Helpers/cn';

interface Props {
  label?: string;
  required?: boolean;
  getValue?: Function;
  options?: any;
  placeholder?: string;
  value?: any;
  pad0?: boolean;
  tooltipText?: string;
  tooltipLoc?: 'Top' | 'Right' | 'Left' | 'Bottom';
  disabled?: boolean;
  withOptions?: boolean;
}

const Select: React.FC<Props> = (props) => {
  const [value, setValue] = useState<any>(
    props.value
      ? props.value
      : props.withOptions
      ? {
          value: '',
          label: '',
        }
      : ''
  );

  const { t } = useTranslate();
  useEffect(() => {
    setValue(
      props.value
        ? props.value
        : props.withOptions
        ? {
            value: '',
            label: '',
          }
        : ''
    );
  }, [props.value, props.withOptions]);

  const handleChange = (value: string | { value: string; label: string }) => {
    if (props.getValue) {
      props.getValue(value);
    }
    setValue(value);
  };

  return (
    <fieldset
      className={cn(
        'relative w-full rounded-md border-2  border-solid border-[#555]  text-white',
        {
          'hover:border-gray-300': !props.disabled,
        }
      )}
    >
      {props.label && (
        <legend className='float-none mb-0 ml-1 w-auto px-1 text-sm'>
          {t(props.label)}
          {!props.disabled && props.required && (
            <span className='min ml-1 text-red-600'>*</span>
          )}
        </legend>
      )}
      <ReactSelect
        className=''
        unstyled
        classNamePrefix=''
        classNames={{
          // container: () => 'h-7',
          indicatorsContainer: () => (props.disabled ? '!hidden' : ''),

          menuPortal: () => '!z-[1300]',
          control: () => ' border-0   px-2  !min-h-7',
          menuList: () => 'rounded border-1 border-solid border-gray-800',
          // menu: () => 'rounded border-1 border-solid border-gray-800',
          option: ({ isFocused, isSelected }) =>
            cn(' bg-primary px-2 py-2  text-white', {
              'bg-[#2a6f43]': isFocused,
              'bg-[#21ab53]': isSelected,
            }),
        }}
        menuPortalTarget={document.querySelector('body')}
        value={
          props.withOptions
            ? { value: value.value, label: t(value.label) }
            : { value: value, label: t(value) }
        }
        options={
          props.withOptions
            ? props.options.map &&
              props.options.map((option) => {
                return {
                  value: option.value,
                  label: t(option.label),
                };
              })
            : props.options.map &&
              props.options.map((option) => {
                return {
                  value: option,
                  label: t(option),
                };
              })
        }
        onChange={(e) =>
          props.withOptions ? handleChange(e) : handleChange(e.value)
        }
        isDisabled={props.disabled}
      />
    </fieldset>
  );
};

export default Select;
