import React, { useEffect, useState } from 'react';
import Select from '../../../new-design/Components/FormElements/Select/Select';

interface Props {
  Options: Array<number>;
  value: number;
  getValue?: Function;
}

/**
 * Select Component of Pagination
 *
 * @todo may be Pagination HOC will be great and easy to manage
 *
 * @category Components
 * @subcategory Pagination
 *
 * @component
 */
const Pagination: React.FC<Props> = (props) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const optionSelected = (value) => {
    if (props.getValue) {
      props.getValue(value);
    }
    setValue(value);
  };

  return (
    <div className='mt-1'>
      <Select
        options={props.Options}
        label='PAGINATION-SECTION-SHOW'
        getValue={optionSelected}
        value={value}
      />
    </div>
  );
};

export default React.memo(Pagination);
