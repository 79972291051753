import React from 'react';
import cloudfront from '../Helpers/cloudfront';
// Video ToolTips

/**
 * Video ToolTips in Youtube and Vimeo
 *
 * @category Components
 * @subcategory Individual
 *
 * @component
 */

type imagetype = React.ImgHTMLAttributes<HTMLImageElement> & {
  resize?: string;
  sharpen?: string;
  piority?: boolean;
  lazy?: boolean;
};

const Image: React.FC<imagetype> = ({
  src,
  // piority,
  resize,
  sharpen,
  ...rest
}) => {
  const {
    fall,
    // lazy
  } = cloudfront(src, resize, sharpen);

  return <img src={fall || src} loading='lazy' {...rest} />;
  // if (piority) return <img src={fall || src} {...rest} />;
  // return (
  //   <LazyLoad
  //     once
  //     className='w-full'
  //     placeholder={<img src={lazy} {...rest} />}
  //   >
  //     <img src={fall || src} {...rest} />
  //   </LazyLoad>
  // );
};

export default Image;

// https://player.vimeo.com/video/876693567?h=6945ddaa84&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479
