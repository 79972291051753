import { default as io, Socket } from 'socket.io-client';
import { create } from 'zustand';

type Store = {
  activity_socket: Socket | null;
  isConnected: () => boolean;
  connect: () => void;
  disconnect: () => void;
};

const useActivity = create<Store>()((set, get) => ({
  activity_socket: io(window.server + '/activity', {
    withCredentials: true,
    autoConnect: false,
  }),
  isConnected: () => {
    return get().activity_socket?.connected || false;
  },
  connect: () => {
    const socket = io(window.server + '/activity', {
      withCredentials: true,
      autoConnect: false,
    });

    socket.on('connect', () => {
      socket.emit('storeID');
      // setIsConnected(true);
    });
    // socket.io.uri = window.server + '/chat';
    socket.connect();
    set({ activity_socket: socket });
  },
  disconnect: () => {
    get().activity_socket.disconnect();
  },
}));

// import { useCallback } from 'react';
// import { default as io } from 'socket.io-client';

// const socket = io(window.server + '/activity', {
//   withCredentials: true,
// });

// socket.on('connect', () => {
//   socket.emit('storeID');
// });

// const useActivity = () => {
//   const isConnected = useCallback(() => {
//     return socket.connected;
//   }, []);
//   const connect = useCallback(() => {
//     // @ts-ignore
//     socket.io.uri = window.server + '/activity';
//     socket.connect();
//   }, []);
//   const disconnect = useCallback(() => {
//     socket.disconnect();
//   }, []);

//   return {
//     activity_socket: socket,
//     connect,
//     disconnect,
//     isConnected,
//   };
// };

export default useActivity;

export const recieveActivity = 'activity.forword';
export const newActivity = 'activity.new';
