import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import useConstants from '../Context/ContextHooks/useConstants';
import useTranslate from '../Context/ContextHooks/useTranslate';
import useUser from '../Context/ContextHooks/useUser';
// import { ToastProvider } from '../Context/NotificationContext';
const LandingLayout = React.lazy(() => import('../layouts/LandingLayout'));
const SidebarLayout = React.lazy(() => import('../layouts/SidebarLayout'));
const PublicLayout = React.lazy(() => import('../layouts/PublicLayout'));

const RouteWrapper: React.FC<{
  children: React.ReactNode;
  isPrivateOnly?: boolean;
  isBoth?: boolean;
  noLayout?: boolean;
  isPublicOnly?: boolean;
}> = ({
  // component: Component,
  // layout: Layout,
  noLayout,
  children,
  isPrivateOnly,
  isBoth,
  isPublicOnly,
}) => {
  const { user } = useUser();
  const naviagte = useNavigate();

  if (user === undefined) {
    return (
      <React.Fragment>
        <div className='centre vh-100'>
          <BallTriangle color='#FFFFFF' height={150} width={150} />
        </div>
      </React.Fragment>
    );
  }

  if (isPrivateOnly && user === null) {
    naviagte('/');
    return <React.Fragment />;
  }

  if (isPublicOnly && user !== null) {
    naviagte('/whatsup');
    return <React.Fragment />;
  }

  if (isBoth && user === null)
    return (
      <Wrapper>
        <PublicLayout>{children}</PublicLayout>
      </Wrapper>
    );

  if (user === null)
    return (
      <Wrapper>
        <LandingLayout>{children}</LandingLayout>
      </Wrapper>
    );
  if (user !== null && noLayout)
    return (
      <Wrapper>
        <>{children}</>
      </Wrapper>
    );
  if (user !== null)
    return (
      <Wrapper>
        <SidebarLayout>{children}</SidebarLayout>
      </Wrapper>
    );
};

RouteWrapper.defaultProps = {
  isPrivateOnly: false,
  isPublicOnly: false,
};

export default React.memo(RouteWrapper);

const Wrapper = ({ children }) => {
  const [cookieConsent, setCookieConsest] = useState(false);
  const [maintenance, setMaintenace] = useState(false);
  const { getConstant } = useConstants();
  const { t } = useTranslate();

  useEffect(() => {
    const cookie = localStorage.getItem('cookie-consest');
    if (cookie === 'true') {
      setCookieConsest(true);
    }
  }, []);

  useEffect(() => {
    const notification = getConstant('MAINTENECE_NOTIFICATION');
    if (notification?.value) {
      setMaintenace(true);
    } else {
      setMaintenace(false);
    }
  }, [getConstant]);

  return (
    <>
      <ToastContainer
        stacked
        className={''}
        draggablePercent={20}
        toastClassName={'p-0 bg-black/40 dark:bg-black/40 rounded-xl mb-0.5'}
        bodyClassName={'p-0 bg-'}
        closeButton={false}
        position='bottom-right'
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme='light'
        transition={Zoom}
      />
      {children}{' '}
      {!cookieConsent && (
        <div className='container-fluid cookie-consent-banner'>
          <div className='row cookie-consent-banner__inner '>
            <div className='col-md-2 cookie-consent-banner__copy centre'>
              {Cookie}
            </div>
            <div className='col-md-8 cookie-consent-banner__copy centre'>
              <div className='cookie-consent-banner__description'>
                This website uses cookies to enhance the user experience and
                analyze performance and traffic. If you do not wish to use
                cookies inherent to this website, please update your browser
                settings to opt-out of the use of cookies. You may experience
                limited functionality on the website if you choose to opt-out.
                To view more details, please refer to our{' '}
                <Link to='/privacy-policy'>Privacy Policy</Link>.
              </div>
            </div>

            <div className='col-md-2 cookie-consent-banner__actions centre'>
              <button
                onClick={() => {
                  setCookieConsest(true);
                  localStorage.setItem('cookie-consest', 'true');
                }}
                className='cookie-consent-banner__cta btn'
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      {maintenance && (
        <div className='fixed bottom-0 z-10  w-full'>
          <div className='relative w-full bg-red-700/80 p-5 text-white'>
            <button
              className='absolute right-1 top-1 p-2'
              onClick={() => {
                setMaintenace(false);
              }}
            >
              <FontAwesomeIcon
                icon={icon({ name: 'times', style: 'light' })}
                size='lg'
              />
            </button>
            <FontAwesomeIcon
              icon={icon({ name: 'exclamation-triangle', style: 'light' })}
              size='2xl'
            />
            <h1 className='text-2xl font-bold'>
              {t('MAINTENANCE-NOTIFICATION-HEADING')}
            </h1>
            <p>{t('MAINTENANCE-NOTIFICATION-BODY')}</p>
          </div>
        </div>
      )}
    </>
  );
};

const Cookie = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='84.178'
    height='79.844'
    viewBox='0 0 84.178 79.844'
  >
    <g
      id='Group_3'
      data-name='Group 3'
      transform='translate(-548.511 -122.768)'
    >
      <g
        id='Group_19'
        data-name='Group 19'
        transform='translate(573.916 122.768)'
      >
        <g id='Group_3-2' data-name='Group 3'>
          <path
            id='Path_1'
            data-name='Path 1'
            d='M648.515,140.359a5.383,5.383,0,0,1-3.871-.615,9.545,9.545,0,0,1-16.693-8.945,6.692,6.692,0,0,1-1.908-7.6,30.368,30.368,0,0,0-33.477,19.1,2.818,2.818,0,0,1,2.314.42,28.021,28.021,0,0,1,26.074-17.649c.767,0,1.542.033,2.32.1q-.014.249-.014.5a9,9,0,0,0,2.083,5.761,11.682,11.682,0,0,0-.329,2.754,11.854,11.854,0,0,0,20.3,8.323,7.707,7.707,0,0,0,1.732.273A28.085,28.085,0,0,1,625.3,180.894a18.072,18.072,0,0,1-1.307,2.485c.153-.015.308-.022.46-.039a30.389,30.389,0,0,0,24.066-42.981Z'
            transform='translate(-592.567 -122.768)'
            fill='#ffffff'
          />
        </g>
        <g id='Group_4' data-name='Group 4' transform='translate(9.909 14.912)'>
          <path
            id='Path_2'
            data-name='Path 2'
            d='M615.954,155.131a3.73,3.73,0,1,0-6.073-1.812A12.349,12.349,0,0,1,615.954,155.131Z'
            transform='translate(-609.75 -148.628)'
            fill='#ffffff'
          />
          <path
            id='Path_3'
            data-name='Path 3'
            d='M611.126,153.392a2.573,2.573,0,1,1,3.688,1.148c.383.183.763.378,1.139.591a3.73,3.73,0,1,0-6.073-1.812A9.421,9.421,0,0,1,611.126,153.392Z'
            transform='translate(-609.75 -148.628)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_5'
          data-name='Group 5'
          transform='translate(21.312 23.761)'
        >
          <path
            id='Path_4'
            data-name='Path 4'
            d='M633.719,168.789a2.76,2.76,0,1,0-4.2-3.5A51.843,51.843,0,0,1,633.719,168.789Z'
            transform='translate(-629.524 -163.973)'
            fill='#ffffff'
          />
          <path
            id='Path_5'
            data-name='Path 5'
            d='M630.444,166a1.609,1.609,0,1,1,2.421,2q.435.385.854.785a2.76,2.76,0,1,0-4.2-3.5Q629.988,165.645,630.444,166Z'
            transform='translate(-629.524 -163.973)'
            fill='#ffffff'
          />
        </g>
        <g id='Group_6' data-name='Group 6' transform='translate(52.98 30.9)'>
          <path
            id='Path_6'
            data-name='Path 6'
            d='M689.656,178.96a2.608,2.608,0,1,1-2.608-2.608A2.608,2.608,0,0,1,689.656,178.96Z'
            transform='translate(-684.44 -176.352)'
            fill='#ffffff'
          />
          <path
            id='Path_7'
            data-name='Path 7'
            d='M687.048,177.505a1.455,1.455,0,1,1-1.455,1.454,1.456,1.456,0,0,1,1.455-1.454m0-1.153a2.608,2.608,0,1,0,2.608,2.608,2.608,2.608,0,0,0-2.608-2.608Z'
            transform='translate(-684.44 -176.352)'
            fill='#ffffff'
          />
        </g>
        <g id='Group_7' data-name='Group 7' transform='translate(29.424 33.52)'>
          <path
            id='Path_8'
            data-name='Path 8'
            d='M643.684,181.055q.673,1.246,1.285,2.554a2.187,2.187,0,0,0-1.377-2.714C643.622,180.949,643.654,181,643.684,181.055Z'
            transform='translate(-643.592 -180.895)'
            fill='#ffffff'
          />
          <path
            id='Path_9'
            data-name='Path 9'
            d='M643.684,181.055q.673,1.246,1.285,2.554a2.187,2.187,0,0,0-1.377-2.714C643.622,180.949,643.654,181,643.684,181.055Z'
            transform='translate(-643.592 -180.895)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_8'
          data-name='Group 8'
          transform='translate(34.731 52.047)'
        >
          <path
            id='Path_10'
            data-name='Path 10'
            d='M656.022,214.638a1.613,1.613,0,1,1-1.613-1.614A1.614,1.614,0,0,1,656.022,214.638Z'
            transform='translate(-652.795 -213.024)'
            fill='#ffffff'
          />
          <path
            id='Path_11'
            data-name='Path 11'
            d='M654.409,214.177a.46.46,0,1,1-.46.46.461.461,0,0,1,.46-.46m0-1.153a1.614,1.614,0,1,0,1.613,1.614,1.614,1.614,0,0,0-1.613-1.614Z'
            transform='translate(-652.795 -213.024)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_9'
          data-name='Group 9'
          transform='translate(29.745 15.489)'
        >
          <path
            id='Path_12'
            data-name='Path 12'
            d='M651.6,153.354a3.726,3.726,0,1,1-3.726-3.726A3.726,3.726,0,0,1,651.6,153.354Z'
            transform='translate(-644.149 -149.628)'
            fill='#ffffff'
          />
          <path
            id='Path_13'
            data-name='Path 13'
            d='M647.875,150.781a2.573,2.573,0,1,1-2.573,2.572,2.576,2.576,0,0,1,2.573-2.572m0-1.153a3.726,3.726,0,1,0,3.726,3.726,3.726,3.726,0,0,0-3.726-3.726Z'
            transform='translate(-644.149 -149.628)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_10'
          data-name='Group 10'
          transform='translate(36.345 37.064)'
        >
          <path
            id='Path_14'
            data-name='Path 14'
            d='M660.953,189.722a2.68,2.68,0,1,1-2.68-2.68A2.68,2.68,0,0,1,660.953,189.722Z'
            transform='translate(-655.593 -187.042)'
            fill='#ffffff'
          />
          <path
            id='Path_15'
            data-name='Path 15'
            d='M658.273,188.2a1.527,1.527,0,1,1-1.526,1.527,1.529,1.529,0,0,1,1.526-1.527m0-1.153a2.68,2.68,0,1,0,2.68,2.68,2.68,2.68,0,0,0-2.68-2.68Z'
            transform='translate(-655.593 -187.042)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_11'
          data-name='Group 11'
          transform='translate(34.155 27.28)'
        >
          <path
            id='Path_16'
            data-name='Path 16'
            d='M655.415,171.885a1.81,1.81,0,1,1-1.81-1.81A1.81,1.81,0,0,1,655.415,171.885Z'
            transform='translate(-651.795 -170.075)'
            fill='#ffffff'
          />
          <path
            id='Path_17'
            data-name='Path 17'
            d='M653.605,171.228a.657.657,0,1,1-.657.656.657.657,0,0,1,.657-.656m0-1.153a1.81,1.81,0,1,0,1.81,1.81,1.81,1.81,0,0,0-1.81-1.81Z'
            transform='translate(-651.795 -170.075)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_12'
          data-name='Group 12'
          transform='translate(21.469 10.959)'
        >
          <path
            id='Path_18'
            data-name='Path 18'
            d='M636.184,144.967a3.194,3.194,0,1,1-3.194-3.194A3.194,3.194,0,0,1,636.184,144.967Z'
            transform='translate(-629.796 -141.773)'
            fill='#ffffff'
          />
          <path
            id='Path_19'
            data-name='Path 19'
            d='M632.99,142.926a2.041,2.041,0,1,1-2.04,2.04,2.043,2.043,0,0,1,2.04-2.04m0-1.153a3.194,3.194,0,1,0,3.194,3.194,3.194,3.194,0,0,0-3.194-3.194Z'
            transform='translate(-629.796 -141.773)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_13'
          data-name='Group 13'
          transform='translate(28.592 4.235)'
        >
          <path
            id='Path_20'
            data-name='Path 20'
            d='M645.981,132.028a1.916,1.916,0,1,1-1.916-1.916A1.916,1.916,0,0,1,645.981,132.028Z'
            transform='translate(-642.149 -130.112)'
            fill='#ffffff'
          />
          <path
            id='Path_21'
            data-name='Path 21'
            d='M644.066,131.265a.763.763,0,1,1-.763.763.764.764,0,0,1,.763-.763m0-1.153a1.916,1.916,0,1,0,1.916,1.916,1.917,1.917,0,0,0-1.916-1.916Z'
            transform='translate(-642.149 -130.112)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_14'
          data-name='Group 14'
          transform='translate(44.738 22.365)'
        >
          <path
            id='Path_22'
            data-name='Path 22'
            d='M676.536,164.745a3.194,3.194,0,1,1-3.195-3.194A3.194,3.194,0,0,1,676.536,164.745Z'
            transform='translate(-670.148 -161.551)'
            fill='#ffffff'
          />
          <path
            id='Path_23'
            data-name='Path 23'
            d='M673.342,162.7a2.04,2.04,0,1,1-2.04,2.04,2.043,2.043,0,0,1,2.04-2.04m0-1.153a3.194,3.194,0,1,0,3.195,3.194,3.194,3.194,0,0,0-3.195-3.194Z'
            transform='translate(-670.148 -161.551)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_15'
          data-name='Group 15'
          transform='translate(50.55 41.271)'
        >
          <path
            id='Path_24'
            data-name='Path 24'
            d='M681.226,196.337Z'
            transform='translate(-680.649 -195.184)'
            fill='#ffffff'
          />
          <path
            id='Path_25'
            data-name='Path 25'
            d='M680.8,194.337a.577.577,0,1,0,.578.577.577.577,0,0,0-.578-.577h0'
            transform='translate(-680.226 -194.337)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_16'
          data-name='Group 16'
          transform='translate(44.738 48.247)'
        >
          <path
            id='Path_26'
            data-name='Path 26'
            d='M673.342,208.031a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,673.342,208.031Z'
            transform='translate(-670.148 -206.434)'
            fill='#ffffff'
          />
          <path
            id='Path_27'
            data-name='Path 27'
            d='M671.745,207.587a.443.443,0,1,1-.444.443.443.443,0,0,1,.444-.443m0-1.153a1.6,1.6,0,1,0,1.6,1.6,1.6,1.6,0,0,0-1.6-1.6Z'
            transform='translate(-670.148 -206.434)'
            fill='#ffffff'
          />
        </g>
        <g
          id='Group_17'
          data-name='Group 17'
          transform='translate(45.317 34.457)'
        >
          <path
            id='Path_28'
            data-name='Path 28'
            d='M673.767,183.827a1.307,1.307,0,1,1-1.307-1.307A1.307,1.307,0,0,1,673.767,183.827Z'
            transform='translate(-671.152 -182.52)'
            fill='#ffffff'
          />
          <path
            id='Path_29'
            data-name='Path 29'
            d='M672.46,183.673a.154.154,0,1,1-.155.154.154.154,0,0,1,.155-.154m0-1.153a1.307,1.307,0,1,0,1.307,1.307,1.308,1.308,0,0,0-1.307-1.307Z'
            transform='translate(-671.152 -182.52)'
            fill='#ffffff'
          />
        </g>
        <g id='Group_18' data-name='Group 18' transform='translate(14.9 7.337)'>
          <path
            id='Path_30'
            data-name='Path 30'
            d='M621.02,136.8a1.307,1.307,0,1,1-1.307-1.307A1.307,1.307,0,0,1,621.02,136.8Z'
            transform='translate(-618.406 -135.491)'
            fill='#ffffff'
          />
          <path
            id='Path_31'
            data-name='Path 31'
            d='M619.713,136.644a.154.154,0,1,1-.154.154.154.154,0,0,1,.154-.154m0-1.153a1.307,1.307,0,1,0,1.307,1.307,1.307,1.307,0,0,0-1.307-1.307Z'
            transform='translate(-618.406 -135.491)'
            fill='#ffffff'
          />
        </g>
      </g>
      <g
        id='Group_47'
        data-name='Group 47'
        transform='translate(548.511 141.842)'
      >
        <g id='Group_46' data-name='Group 46' transform='translate(0 0)'>
          <g id='Group_20' data-name='Group 20'>
            <path
              id='Path_32'
              data-name='Path 32'
              d='M578.9,158.151a28.079,28.079,0,1,1-28.078,28.079A28.111,28.111,0,0,1,578.9,158.151m0-2.307a30.385,30.385,0,1,0,30.385,30.385A30.385,30.385,0,0,0,578.9,155.844Z'
              transform='translate(-548.511 -155.844)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_21'
            data-name='Group 21'
            transform='translate(11.906 14.913)'
          >
            <path
              id='Path_33'
              data-name='Path 33'
              d='M576.61,185.431a3.726,3.726,0,1,1-3.726-3.726A3.726,3.726,0,0,1,576.61,185.431Z'
              transform='translate(-569.158 -181.705)'
              fill='#ffffff'
            />
            <path
              id='Path_34'
              data-name='Path 34'
              d='M572.884,182.858a2.572,2.572,0,1,1-2.572,2.572,2.575,2.575,0,0,1,2.572-2.572m0-1.153a3.726,3.726,0,1,0,3.726,3.726,3.726,3.726,0,0,0-3.726-3.726Z'
              transform='translate(-569.158 -181.705)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_22'
            data-name='Group 22'
            transform='translate(10.215 25.252)'
          >
            <path
              id='Path_35'
              data-name='Path 35'
              d='M568.253,200.648a1.014,1.014,0,1,1-1.014-1.014A1.014,1.014,0,0,1,568.253,200.648Z'
              transform='translate(-566.225 -199.634)'
              fill='#ffffff'
            />
            <path
              id='Path_36'
              data-name='Path 36'
              d='M567.239,199.634a1.014,1.014,0,1,0,1.014,1.014,1.014,1.014,0,0,0-1.014-1.014Z'
              transform='translate(-566.225 -199.634)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_23'
            data-name='Group 23'
            transform='translate(22.889 23.761)'
          >
            <circle
              id='Ellipse_1'
              data-name='Ellipse 1'
              cx='2.768'
              cy='2.768'
              r='2.768'
              transform='translate(0 0)'
              fill='#ffffff'
            />
            <path
              id='Path_37'
              data-name='Path 37'
              d='M590.971,198.2a1.615,1.615,0,1,1-1.615,1.615,1.616,1.616,0,0,1,1.615-1.615m0-1.153a2.768,2.768,0,1,0,2.768,2.768,2.768,2.768,0,0,0-2.768-2.768Z'
              transform='translate(-588.203 -197.049)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_24'
            data-name='Group 24'
            transform='translate(9.262 34.457)'
          >
            <path
              id='Path_38'
              data-name='Path 38'
              d='M569.789,218.2a2.608,2.608,0,1,1-2.608-2.608A2.608,2.608,0,0,1,569.789,218.2Z'
              transform='translate(-564.573 -215.596)'
              fill='#ffffff'
            />
            <path
              id='Path_39'
              data-name='Path 39'
              d='M567.181,216.749a1.455,1.455,0,1,1-1.455,1.455,1.457,1.457,0,0,1,1.455-1.455m0-1.153a2.608,2.608,0,1,0,2.608,2.608,2.608,2.608,0,0,0-2.608-2.608Z'
              transform='translate(-564.573 -215.596)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_25'
            data-name='Group 25'
            transform='translate(1.331 28.292)'
          >
            <path
              id='Path_40'
              data-name='Path 40'
              d='M556.035,207.513a2.608,2.608,0,1,1-2.608-2.608A2.608,2.608,0,0,1,556.035,207.513Z'
              transform='translate(-550.819 -204.905)'
              fill='#ffffff'
            />
            <path
              id='Path_41'
              data-name='Path 41'
              d='M553.428,206.058a1.455,1.455,0,1,1-1.456,1.455,1.457,1.457,0,0,1,1.456-1.455m0-1.153a2.608,2.608,0,1,0,2.608,2.608,2.609,2.609,0,0,0-2.608-2.608Z'
              transform='translate(-550.819 -204.905)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_26'
            data-name='Group 26'
            transform='translate(54.977 30.9)'
          >
            <path
              id='Path_42'
              data-name='Path 42'
              d='M649.064,212.036a2.608,2.608,0,1,1-2.608-2.608A2.608,2.608,0,0,1,649.064,212.036Z'
              transform='translate(-643.848 -209.428)'
              fill='#ffffff'
            />
            <path
              id='Path_43'
              data-name='Path 43'
              d='M646.456,210.581A1.455,1.455,0,1,1,645,212.036a1.457,1.457,0,0,1,1.455-1.455m0-1.153a2.608,2.608,0,1,0,2.608,2.608,2.608,2.608,0,0,0-2.608-2.608Z'
              transform='translate(-643.848 -209.428)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_27'
            data-name='Group 27'
            transform='translate(16.785 28.655)'
          >
            <path
              id='Path_44'
              data-name='Path 44'
              d='M580.28,206.866a1.331,1.331,0,1,1-1.33-1.33A1.331,1.331,0,0,1,580.28,206.866Z'
              transform='translate(-577.619 -205.536)'
              fill='#ffffff'
            />
            <path
              id='Path_45'
              data-name='Path 45'
              d='M578.95,206.689a.177.177,0,1,1-.178.177.177.177,0,0,1,.178-.177m0-1.153a1.331,1.331,0,1,0,1.33,1.33,1.331,1.331,0,0,0-1.33-1.33Z'
              transform='translate(-577.619 -205.536)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_28'
            data-name='Group 28'
            transform='translate(12.243 48.035)'
          >
            <path
              id='Path_46'
              data-name='Path 46'
              d='M576.981,242.762a3.62,3.62,0,1,1-3.619-3.619A3.62,3.62,0,0,1,576.981,242.762Z'
              transform='translate(-569.742 -239.143)'
              fill='#ffffff'
            />
            <path
              id='Path_47'
              data-name='Path 47'
              d='M573.362,240.3a2.466,2.466,0,1,1-2.467,2.466,2.469,2.469,0,0,1,2.467-2.466m0-1.153a3.619,3.619,0,1,0,3.619,3.619,3.62,3.62,0,0,0-3.619-3.619Z'
              transform='translate(-569.742 -239.143)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_29'
            data-name='Group 29'
            transform='translate(20.973 44.627)'
          >
            <path
              id='Path_48'
              data-name='Path 48'
              d='M588.5,235.043a1.81,1.81,0,1,1-1.81-1.81A1.81,1.81,0,0,1,588.5,235.043Z'
              transform='translate(-584.881 -233.233)'
              fill='#ffffff'
            />
            <path
              id='Path_49'
              data-name='Path 49'
              d='M586.691,234.386a.657.657,0,1,1-.656.657.657.657,0,0,1,.656-.657m0-1.153a1.81,1.81,0,1,0,1.81,1.81,1.809,1.809,0,0,0-1.81-1.81Z'
              transform='translate(-584.881 -233.233)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_30'
            data-name='Group 30'
            transform='translate(28.425 33.378)'
          >
            <path
              id='Path_50'
              data-name='Path 50'
              d='M602.274,215.961a2.236,2.236,0,1,1-2.236-2.235A2.236,2.236,0,0,1,602.274,215.961Z'
              transform='translate(-597.803 -213.726)'
              fill='#ffffff'
            />
            <path
              id='Path_51'
              data-name='Path 51'
              d='M600.039,214.879a1.082,1.082,0,1,1-1.082,1.082,1.083,1.083,0,0,1,1.082-1.082m0-1.153a2.236,2.236,0,1,0,2.236,2.235,2.235,2.235,0,0,0-2.236-2.235Z'
              transform='translate(-597.803 -213.726)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_31'
            data-name='Group 31'
            transform='translate(36.729 52.048)'
          >
            <path
              id='Path_52'
              data-name='Path 52'
              d='M615.429,247.714a1.613,1.613,0,1,1-1.614-1.613A1.613,1.613,0,0,1,615.429,247.714Z'
              transform='translate(-612.203 -246.101)'
              fill='#ffffff'
            />
            <path
              id='Path_53'
              data-name='Path 53'
              d='M613.816,247.254a.46.46,0,1,1-.46.46.461.461,0,0,1,.46-.46m0-1.153a1.613,1.613,0,1,0,1.614,1.613,1.613,1.613,0,0,0-1.614-1.613Z'
              transform='translate(-612.203 -246.101)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_32'
            data-name='Group 32'
            transform='translate(34.05 14.336)'
          >
            <path
              id='Path_54'
              data-name='Path 54'
              d='M615.009,184.431a3.726,3.726,0,1,1-3.726-3.726A3.726,3.726,0,0,1,615.009,184.431Z'
              transform='translate(-607.557 -180.705)'
              fill='#ffffff'
            />
            <path
              id='Path_55'
              data-name='Path 55'
              d='M611.283,181.858a2.572,2.572,0,1,1-2.573,2.572,2.576,2.576,0,0,1,2.573-2.572m0-1.153a3.726,3.726,0,1,0,3.726,3.726,3.725,3.725,0,0,0-3.726-3.726Z'
              transform='translate(-607.557 -180.705)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_33'
            data-name='Group 33'
            transform='translate(38.342 37.065)'
          >
            <path
              id='Path_56'
              data-name='Path 56'
              d='M620.36,222.8a2.68,2.68,0,1,1-2.68-2.68A2.68,2.68,0,0,1,620.36,222.8Z'
              transform='translate(-615 -220.119)'
              fill='#ffffff'
            />
            <path
              id='Path_57'
              data-name='Path 57'
              d='M617.68,221.272a1.527,1.527,0,1,1-1.527,1.527,1.529,1.529,0,0,1,1.527-1.527m0-1.153a2.68,2.68,0,1,0,2.68,2.68,2.68,2.68,0,0,0-2.68-2.68Z'
              transform='translate(-615 -220.119)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_34'
            data-name='Group 34'
            transform='translate(25.657 53.661)'
          >
            <path
              id='Path_58'
              data-name='Path 58'
              d='M597.731,251.262a2.364,2.364,0,1,1-2.364-2.364A2.364,2.364,0,0,1,597.731,251.262Z'
              transform='translate(-593.003 -248.898)'
              fill='#ffffff'
            />
            <path
              id='Path_59'
              data-name='Path 59'
              d='M595.367,250.051a1.211,1.211,0,1,1-1.211,1.211,1.212,1.212,0,0,1,1.211-1.211m0-1.153a2.364,2.364,0,1,0,2.364,2.364,2.364,2.364,0,0,0-2.364-2.364Z'
              transform='translate(-593.003 -248.898)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_35'
            data-name='Group 35'
            transform='translate(36.152 27.28)'
          >
            <path
              id='Path_60'
              data-name='Path 60'
              d='M614.823,204.961a1.81,1.81,0,1,1-1.81-1.81A1.81,1.81,0,0,1,614.823,204.961Z'
              transform='translate(-611.203 -203.151)'
              fill='#ffffff'
            />
            <path
              id='Path_61'
              data-name='Path 61'
              d='M613.013,204.3a.657.657,0,1,1-.657.657.657.657,0,0,1,.657-.657m0-1.153a1.81,1.81,0,1,0,1.81,1.81,1.809,1.809,0,0,0-1.81-1.81Z'
              transform='translate(-611.203 -203.151)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_36'
            data-name='Group 36'
            transform='translate(23.466 10.96)'
          >
            <path
              id='Path_62'
              data-name='Path 62'
              d='M595.591,178.044a3.194,3.194,0,1,1-3.194-3.194A3.193,3.193,0,0,1,595.591,178.044Z'
              transform='translate(-589.203 -174.85)'
              fill='#ffffff'
            />
            <path
              id='Path_63'
              data-name='Path 63'
              d='M592.4,176a2.04,2.04,0,1,1-2.041,2.04A2.042,2.042,0,0,1,592.4,176m0-1.153a3.194,3.194,0,1,0,3.194,3.194,3.194,3.194,0,0,0-3.194-3.194Z'
              transform='translate(-589.203 -174.85)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_37'
            data-name='Group 37'
            transform='translate(32.896 4.812)'
          >
            <path
              id='Path_64'
              data-name='Path 64'
              d='M609.39,166.105a1.916,1.916,0,1,1-1.916-1.916A1.916,1.916,0,0,1,609.39,166.105Z'
              transform='translate(-605.557 -164.189)'
              fill='#ffffff'
            />
            <path
              id='Path_65'
              data-name='Path 65'
              d='M607.473,165.342a.763.763,0,1,1-.763.763.764.764,0,0,1,.763-.763m0-1.153a1.916,1.916,0,1,0,1.916,1.916,1.916,1.916,0,0,0-1.916-1.916Z'
              transform='translate(-605.557 -164.189)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_38'
            data-name='Group 38'
            transform='translate(46.736 22.365)'
          >
            <path
              id='Path_66'
              data-name='Path 66'
              d='M635.944,197.821a3.194,3.194,0,1,1-3.194-3.194A3.194,3.194,0,0,1,635.944,197.821Z'
              transform='translate(-629.557 -194.627)'
              fill='#ffffff'
            />
            <path
              id='Path_67'
              data-name='Path 67'
              d='M632.751,195.78a2.041,2.041,0,1,1-2.04,2.041,2.043,2.043,0,0,1,2.04-2.041m0-1.153a3.194,3.194,0,1,0,3.194,3.194,3.193,3.193,0,0,0-3.194-3.194Z'
              transform='translate(-629.557 -194.627)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_39'
            data-name='Group 39'
            transform='translate(52.547 41.272)'
          >
            <path
              id='Path_68'
              data-name='Path 68'
              d='M640.633,229.414Z'
              transform='translate(-640.056 -228.261)'
              fill='#ffffff'
            />
            <path
              id='Path_69'
              data-name='Path 69'
              d='M640.21,227.414a.577.577,0,1,0,.578.577.576.576,0,0,0-.578-.577h0'
              transform='translate(-639.633 -227.414)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_40'
            data-name='Group 40'
            transform='translate(46.736 48.247)'
          >
            <path
              id='Path_70'
              data-name='Path 70'
              d='M632.751,241.107a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,632.751,241.107Z'
              transform='translate(-629.557 -239.51)'
              fill='#ffffff'
            />
            <path
              id='Path_71'
              data-name='Path 71'
              d='M631.153,240.663a.443.443,0,1,1-.443.443.444.444,0,0,1,.443-.443m0-1.153a1.6,1.6,0,1,0,1.6,1.6,1.6,1.6,0,0,0-1.6-1.6Z'
              transform='translate(-629.557 -239.51)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_41'
            data-name='Group 41'
            transform='translate(30.661 43.776)'
          >
            <path
              id='Path_72'
              data-name='Path 72'
              d='M604.341,233.087a1.331,1.331,0,1,1-1.331-1.331A1.331,1.331,0,0,1,604.341,233.087Z'
              transform='translate(-601.68 -231.756)'
              fill='#ffffff'
            />
            <path
              id='Path_73'
              data-name='Path 73'
              d='M603.01,232.909a.178.178,0,1,1-.177.178.178.178,0,0,1,.177-.178m0-1.153a1.331,1.331,0,1,0,1.331,1.331,1.331,1.331,0,0,0-1.331-1.331Z'
              transform='translate(-601.68 -231.756)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_42'
            data-name='Group 42'
            transform='translate(45.245 10.774)'
          >
            <path
              id='Path_74'
              data-name='Path 74'
              d='M631.111,176.6a2.069,2.069,0,1,1-2.07-2.07A2.069,2.069,0,0,1,631.111,176.6Z'
              transform='translate(-626.972 -174.527)'
              fill='#ffffff'
            />
            <path
              id='Path_75'
              data-name='Path 75'
              d='M629.041,175.68a.916.916,0,1,1-.916.916.918.918,0,0,1,.916-.916m0-1.153a2.07,2.07,0,1,0,2.07,2.07,2.069,2.069,0,0,0-2.07-2.07Z'
              transform='translate(-626.972 -174.527)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_43'
            data-name='Group 43'
            transform='translate(47.315 34.457)'
          >
            <path
              id='Path_76'
              data-name='Path 76'
              d='M633.175,216.9a1.308,1.308,0,1,1-1.308-1.307A1.308,1.308,0,0,1,633.175,216.9Z'
              transform='translate(-630.56 -215.596)'
              fill='#ffffff'
            />
            <path
              id='Path_77'
              data-name='Path 77'
              d='M631.867,216.749a.154.154,0,1,1-.154.154.154.154,0,0,1,.154-.154m0-1.153a1.307,1.307,0,1,0,1.308,1.307,1.307,1.307,0,0,0-1.308-1.307Z'
              transform='translate(-630.56 -215.596)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_44'
            data-name='Group 44'
            transform='translate(16.897 7.337)'
          >
            <path
              id='Path_78'
              data-name='Path 78'
              d='M580.428,169.874a1.308,1.308,0,1,1-1.308-1.307A1.308,1.308,0,0,1,580.428,169.874Z'
              transform='translate(-577.813 -168.567)'
              fill='#ffffff'
            />
            <path
              id='Path_79'
              data-name='Path 79'
              d='M579.12,169.72a.154.154,0,1,1-.154.154.154.154,0,0,1,.154-.154m0-1.153a1.308,1.308,0,1,0,1.308,1.307,1.308,1.308,0,0,0-1.308-1.307Z'
              transform='translate(-577.813 -168.567)'
              fill='#ffffff'
            />
          </g>
          <g
            id='Group_45'
            data-name='Group 45'
            transform='translate(20.636 36.767)'
          >
            <path
              id='Path_80'
              data-name='Path 80'
              d='M585.3,221.6Z'
              transform='translate(-584.719 -220.449)'
              fill='#ffffff'
            />
            <path
              id='Path_81'
              data-name='Path 81'
              d='M584.873,219.6a.577.577,0,1,0,.578.577.577.577,0,0,0-.578-.577h0'
              transform='translate(-584.296 -219.602)'
              fill='#ffffff'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
