import { default as io, Socket } from 'socket.io-client';
import { create } from 'zustand';

type Store = {
  chat_socket: Socket | null;
  isConnected: () => boolean;
  connect: () => void;
  disconnect: () => void;
};

const useChat = create<Store>()((set, get) => ({
  chat_socket: io(window.server + '/chat', {
    withCredentials: true,
    autoConnect: false,
  }),
  isConnected: () => {
    return get().chat_socket?.connected || false;
  },
  connect: () => {
    const socket = io(window.server + '/chat', {
      withCredentials: true,
      autoConnect: false,
    });

    socket.on('connect', () => {
      socket.emit('storeID');
      // setIsConnected(true);
    });
    // socket.io.uri = window.server + '/chat';
    socket.connect();
    set({ chat_socket: socket });
  },
  disconnect: () => {
    get().chat_socket.disconnect();
  },
}));

export default useChat;

export const sendMessage = 'message.send';
export const sendMessageBulk = 'message.send.bulk';
export const recieveMessage = 'message.recieve';
export const sendRead = 'message.read';
export const messageEdit = 'message.edit';
export const messageEditRecieve = 'message.edit.recieve';

export const deleteMessage = 'message.delete';
export const deleteMessageRecieve = 'message.delete.recieve';

export const deleteChat = 'chat.delete';

export const recieveReadMessage = 'message.recieve.read';
export const sendStartedTyping = 'message.write';
export const recieveTyping = 'message.write.send';
export const exitGroupChat = 'message.group.exit';
export const editGroupChat = 'message.group.edit';
export const editGroupChatRecieve = 'message.group.edit.recieve';
export const sendEmojiReaction = 'message.emoji';
export const receiveEmojiReaction = 'message.emoji.recieve';
