import { init } from '@sentry/react';
import { Suspense, useEffect, useState } from 'react';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { BallTriangle } from 'react-loader-spinner';
import App from './App';
// import './assets/FALibrary/FALibrary';
import './assets/scss/Index.scss';
import { ApplicationProvider } from './Context/ApplicationContext';
import { environment, paypal } from './Global';
/** Starting Point of React Application */
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';

import axios from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import { recaptha } from './BackendConnection/Logs';
import Fallback from './new-design/Fallback';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (environment !== 'development') {
  init({
    dsn: 'https://4b94f37386364b4898b89cf2240a6ac7@o4504880752230400.ingest.sentry.io/4504899950084096',
    environment: environment,
    tracesSampleRate: 0,
    sampleRate: 0,
    profilesSampleRate: 0,
    ignoreErrors: [/.*/],
    ignoreTransactions: [/.*/],
    enableTracing: false,
  });
}

const Providers = () => {
  const [server, setServer] = useState(null);

  const checkserver = async () => {
    try {
      const server = await axios.get(
        process.env.REACT_APP_API_SERVER + '/health',
        {
          timeout: 3000,
        }
      );
      if (server.status === 200) {
        window.server = process.env.REACT_APP_API_SERVER;
        setServer(true);
        return;
      }
      throw new Error('Server 1 Not Online');
    } catch {
      if (!process.env.REACT_APP_API_SERVER_2) {
        setServer(false);
        return;
      }
      try {
        const server = await axios.get(
          process.env.REACT_APP_API_SERVER_2 + '/health'
        );
        if (server.status === 200) {
          window.server = process.env.REACT_APP_API_SERVER_2;
          setServer(true);
          return;
        }
        throw new Error('Server 2 Not Online');
      } catch {
        setServer(false);
      }
    }
  };

  useEffect(() => {
    checkserver();
  }, []);

  if (server === null)
    return (
      <div className='centre vh-100'>
        <BallTriangle color='#FFFFFF' height={150} width={150} />
      </div>
    );

  if (server === false)
    return (
      <>
        <body className='flex h-full min-h-dvh items-center justify-center bg-primary text-white'>
          <div className='flex max-w-md flex-col items-center self-center px-5 text-center'>
            <img
              src='https://marooze.s3.eu-west-3.amazonaws.com/static/logofull-white.png'
              className='h-[100vw] max-h-[500px] rotate-90 p-5'
            />
            <div className='mb-2 text-2xl font-semibold '>
              Offline for maintenance
            </div>
            <p className='mb-1 '>
              We will be shortly back and just setting up some new amazing
              features for you In the meantime get inspired by new creations
              from our users on{' '}
              <a
                href='https://www.instagram.com/marooze.official/'
                className='underline underline-offset-2'
                target='_blank'
                rel='noreferrer'
              >
                marooze.official
              </a>
            </p>
          </div>
        </body>
      </>
    );

  return (
    // <HttpsRedirect disabled>
    <ErrorBoundary FallbackComponent={Fallback}>
      <PayPalScriptProvider
        options={{
          currency: 'EUR',
          clientId: paypal,
        }}
      >
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <ApplicationProvider>
              <Suspense
                fallback={
                  <div className='centre vh-100'>
                    <BallTriangle color='#FFFFFF' height={150} width={150} />
                  </div>
                }
              >
                <GoogleReCaptchaProvider reCaptchaKey='6LciVlghAAAAAPkYL4OpaQBg-AP9XTWBj--FwNPT'>
                  <GoogleReCaptcha
                    onVerify={async (e) => {
                      recaptha(e);
                    }}
                  />
                  <App />
                </GoogleReCaptchaProvider>
              </Suspense>
            </ApplicationProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </PayPalScriptProvider>
    </ErrorBoundary>
    // </HttpsRedirect>
  );
};

export default Providers;
