import find from 'lodash/find';
import { useCallback, useContext, useMemo } from 'react';
import { loadLanguage } from '../../BackendConnection/Text';
import usePopup from '../../hooks/usePopup';
import { ApplicationContext } from '../ApplicationContext';

/** @module Hooks */
/**
 * Expose methods to translate language
 *
 *

 *
 * @function
 */

const useTranslate = () => {
  const { language, fallback_language, user } = useContext(ApplicationContext);
  const { customPopup } = usePopup();

  const { state: userState } = user;
  const { state: language_state, setState: language_setState } = language;
  const { state: fallback_state, setState: fallback_setState } =
    fallback_language;

  const t = useCallback(
    (_id: string) => {
      let lang: { text: string } = find(language_state.texts, { _id });

      if (lang && lang.text !== '') {
        return lang.text;
      }
      lang = find(fallback_state.texts, { _id });

      if (lang && lang.text !== '') {
        return lang.text;
      }
      return _id;
    },
    [fallback_state.texts, language_state.texts]
  );
  // const tServer = useCallback(async (_id: string, language: string) => {
  //   const translation = await translateServer({ id: _id, lang: language });
  //   return translation.translation;
  // }, []);

  const tStrict = useCallback(
    (_id: string) => {
      let lang = find(language_state.texts, { _id });
      if (lang && lang.text !== '') {
        return lang.text;
      }
      lang = find(fallback_state.texts, { _id });
      if (lang) {
        return lang.text;
      }
      return null;
    },
    [fallback_state.texts, language_state.texts]
  );

  const tFall = useCallback(
    (_id: string) => {
      let lang = find(fallback_state.texts, { _id });
      if (lang) {
        return lang.text;
      }
      return _id;
    },
    [fallback_state]
  );

  const _loadLanguage = useCallback(
    async (font) => {
      const data = await loadLanguage(font);

      document.documentElement.lang = data?._id.split('-')[0];

      language_setState((language_state) => {
        if (language_state?._id !== font) {
          return data;
        }
        return language_state;
      });
    },
    [language_setState]
  );

  const loadLanguageForce = useCallback(
    async (font) => {
      const data = await loadLanguage(font);

      language_setState(data);
    },
    [language_setState]
  );

  const loadFallbackLanguage = useCallback(async () => {
    const data = await loadLanguage('en-US');

    fallback_setState(data);
  }, [fallback_setState]);

  const lang_id = useMemo(() => {
    return language.state?._id;
  }, [language.state?._id]);

  const translateOnFly = async (description) => {
    let lang = userState?.settings?.language.slice(0, 2);

    if (userState?.settings?.language === 'zh-CHT') {
      lang = 'zh-CHT';
    }
    let text = description.replace(/<\/?[^>]+(>|$)/g, ' ');
    text = text.replace(/&nbsp;/g, ' ');

    text = encodeURIComponent(text);

    const requestOptions: any = {
      method: 'GET',
      headers: {
        'x-rapidapi-host': 'microsoft-azure-translation-v1.p.rapidapi.com',
        'x-rapidapi-key': '84ce3e52admshf044f791d69b195p15c8e4jsn1f86974b71e9',
        accept: 'application/json',
        useQueryString: true,
      },
    };

    const response = await fetch(
      `https://microsoft-azure-translationv1.p.rapidapi.com/translate?to=${lang}&text=${text}`,
      requestOptions
    );

    let textres = await response.text();

    textres = textres.replace(/<\/?[^>]+(>|$)/g, ' ');
    textres = textres.replace(/&amp;/g, '&');

    customPopup(<div className='break-words'>{textres}</div>, '');
    // notifier.modal(decodeURIComponent(textres));
  };

  return {
    enlanguage: fallback_language.state,
    lang_id,
    t,
    // tServer,
    tFall,
    tStrict,
    loadLanguage: _loadLanguage,
    loadLanguageForce,
    loadFallbackLanguage,
    translateOnFly,
  };
};

export default useTranslate;
