import React from 'react';
import { logoRound } from '../../assets/ImageLibrary/ImageLibrary';
import SmallCard from '../../components/Cards/NewCards/SmallCard';
import useTranslate from '../ContextHooks/useTranslate';

interface Props {
  message: string;
  delay?: number;
  static?: boolean;
  closeToast?: () => {};
}

const GeneralNotifier: React.FC<Props> = (props) => {
  const { t } = useTranslate();

  return (
    <div className='min-w-80'>
      <SmallCard
        opacity
        avatar={logoRound}
        line1={t(props.message)}
        onlyLine1
        handleClose={() => {
          props.closeToast && props.closeToast();
        }}
      />
    </div>
  );
};
GeneralNotifier.defaultProps = {
  delay: 4000,
  static: false,
};
export default React.memo(GeneralNotifier);
