import { create } from 'zustand';
import { loadCreditData } from '../BackendConnection/Credits';

type Store = {
  credits: { locked: number; open: number; paid: number; not_paid: number };
  //   _loadCredits = useCallback(async () => {
  //     const count = await loadCreditData();
  //     setState(count);
  //   }, [setState]);
  credits_modal: boolean;
  // eslint-disable-next-line no-unused-vars
  setCreditsdModal: (arg0: boolean) => void;
  loadCredits: () => void;
};

const useCredits = create<Store>()((set) => ({
  credits: { locked: 0, open: 0, paid: 0, not_paid: 0 },
  credits_modal: false,
  setCreditsdModal: (e: boolean) => {
    set((b) => ({ ...b, credits_modal: e }));
  },
  loadCredits: async () => {
    const count = await loadCreditData();
    set((b) => ({ ...b, credits: count }));
  },
}));

export default useCredits;
