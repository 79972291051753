import { loadStripe, Stripe } from '@stripe/stripe-js';

interface EnvironmentConfig {
  server: string;
  environment?: string;
  REACT_APP_STRIPE_KEY?: string;
  REACT_APP_STRIPE_ACCOUNT?: string;
  paypal?: string;
}

const environmentConfigs: Record<string, EnvironmentConfig> = {
  'www.marooze.com': {
    server: process.env.REACT_APP_API_SERVER || 'https://api-v3.marooze.com',
    environment: process.env.REACT_APP_ENVIRONMENT || 'production',
    REACT_APP_STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY!,
    REACT_APP_STRIPE_ACCOUNT: process.env.REACT_APP_STRIPE_ACCOUNT!,
    paypal: process.env.REACT_APP_PAYPAL!,
  },
  'www.stage-mrz-marooze.com': {
    server:
      process.env.REACT_APP_API_SERVER || 'https://api.stage-mrz-marooze.com',
    environment: 'staging',
  },
  localhost: {
    server: process.env.REACT_APP_API_SERVER || 'http://localhost:4500',
  },
  '192.168.10.2': {
    server: 'http://192.168.10.2:5000',
  },
};

/** Server Address */
let server = 'http://localhost:5000';

/** Environment */
let environment = 'development';

/** Mapbox ID */
const REACT_APP_MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY || '';

/** Stripe PUBLIC Key */
let REACT_APP_STRIPE_KEY =
  process.env.REACT_APP_STRIPE_KEY ||
  'pk_test_51KoBKSAYDJ7P14OQ1NQKwNqeLhAgcn6eoYjLPaSOl4oKjDgC6AsEjPBsaOKQ8HCCa4SLaczxmYSicZvib32evdvX00ND60iHKY';

/** Stripe PUBLIC ACCOUNT */
let REACT_APP_STRIPE_ACCOUNT = process.env.REACT_APP_STRIPE_ACCOUNT || '';

/** Cloudinary URL */
// const cloudinary =
//   process.env.REACT_APP_CLOUDINARY_URL ||
//   'https://res.cloudinary.com/marooze/image/upload/';

/** Paypal Link */
let paypal = process.env.REACT_APP_PAYPAL || '';

const hostname = window.location.hostname;
const config = environmentConfigs[hostname];

if (config) {
  server = config.server;
  environment = config.environment || environment;
  REACT_APP_STRIPE_KEY = config.REACT_APP_STRIPE_KEY || REACT_APP_STRIPE_KEY;
  REACT_APP_STRIPE_ACCOUNT =
    config.REACT_APP_STRIPE_ACCOUNT || REACT_APP_STRIPE_ACCOUNT;
  paypal = config.paypal || paypal;
}

let stripe: Stripe | null = null;

/** Loading Stripe */
loadStripe(REACT_APP_STRIPE_KEY, {
  stripeAccount: REACT_APP_STRIPE_ACCOUNT,
}).then((stripeA) => {
  stripe = stripeA;
});

const deletedmonth = new Date();
deletedmonth.setMonth(deletedmonth.getMonth() - 1);

export {
  deletedmonth,
  environment,
  paypal,
  REACT_APP_MAPBOX_API_KEY,
  server,
  stripe,
};
