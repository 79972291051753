import React from 'react';
import { logoRound } from '../../assets/ImageLibrary/ImageLibrary';
import SmallCard from '../../components/Cards/NewCards/SmallCard';
import useTranslate from '../ContextHooks/useTranslate';

interface Props {
  message: string;
  type: 'WARNING' | 'ERROR';
  // id: number;
  closeToast?: () => {};
}

const ProblemNotifier: React.FC<Props> = (props) => {
  const { t } = useTranslate();

  return (
    <div className='min-w-80'>
      <SmallCard
        opacity
        avatar={logoRound}
        line1={t(props.message)}
        onlyLine1
        handleClose={() => {
          props.closeToast && props.closeToast();
        }}
      />
    </div>
  );
};

export default React.memo(ProblemNotifier);
