import axios from 'axios';
import { useCallback, useContext } from 'react';
import { ApplicationContext } from '../ApplicationContext';

type getConstantType = (_id: string) => {
  _id: string;
  name: string;
  desc: string;
  type: string;
  category: string;
  updatedAt: string;
  value: any;
};

const useConstants = () => {
  const { constants } = useContext(ApplicationContext);
  // const [loaded, setLoaded] = useState(false);

  const { state, setState } = constants;

  const getConstant: getConstantType = useCallback(
    (_id) => {
      const constant = state.find((f) => f?._id === _id);
      if (constant) return constant;
      else
        return {
          _id: _id,
          name: '',
          desc: '',
          type: '',
          category: '',
          updatedAt: '',
          value: '',
        };
    },
    [state]
  );

  const refreshConsants = useCallback(async () => {
    const response = await axios.get(
      window.server + '/api/v1/documents/constant',
      {
        withCredentials: true,
      }
    );

    setState(response?.data?.data);
  }, [setState]);

  const verifyPassword = useCallback(async (pass) => {
    const response = await axios.post(
      window.server + '/api/v1/documents/constant/verify',
      { verify: pass },
      {
        withCredentials: true,
      }
    );

    return response.data;
  }, []);

  // useEffect(() => {
  //   if (!loaded) {
  //     refreshConsants();
  //     setLoaded(true);
  //   }
  // }, [loaded]);

  return {
    constants: state,
    getConstant,
    refresh: refreshConsants,
    verifyPassword,
  };
};

export default useConstants;
