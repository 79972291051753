import React, { useEffect, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { Popover, PopoverProps } from 'react-tiny-popover';
import useTranslate from '../Context/ContextHooks/useTranslate';
import useUser from '../Context/ContextHooks/useUser';
import { cn } from '../Helpers/cn';
interface Props {
  title: string;
  text: string;
  link: string;
  children: React.ReactNode;
  delay?: number;
  className?: string;
  popover?: PopoverProps;
  popoverdisabled?: boolean;
}

const NewPopover: React.FC<Props> = (props) => {
  const { user } = useUser();
  const [isPopoverOpen, setIsPopupOpen] = useState(false);
  const { t } = useTranslate();
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    // let timer: ReturnType<typeof setTimeout>;
    // if (isPopoverOpen)
    //   timer = setTimeout(() => {
    //     setIsPopupOpen(false);
    //   }, 3000); // delay

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  if (!isDesktop) return props.children;
  if (!user?.settings.tooltips) return props.children;
  if (props.popoverdisabled) return props.children;

  return (
    <Popover
      // key={props.title}
      positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority// preferred positions by priority
      {...props.popover}
      isOpen={isPopoverOpen}
      containerClassName='z-[9999]'
      onClickOutside={() => {
        timer.current = setTimeout(() => {
          setIsPopupOpen(false);
        }, 1000);
      }}
      content={
        <div
          onMouseEnter={() => {
            clearTimeout(timer.current);
            setIsPopupOpen(true);
          }}
          onMouseLeave={() => {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
              setIsPopupOpen(false);
            }, 1000);
          }}
          className='relative mt-1 max-w-[250px] rounded-2xl border border-light_grey bg-primary/90 '
        >
          <div className='mt-2 flex gap-4  px-4 py-4'>
            <div className='font-[400] leading-[16px]'>
              <h1 className='text-[1.2rem] text-white md:text-[1.2rem]'>
                {t(props.title)}
              </h1>
              <p className='mt-4 text-[0.7rem]  leading-5 text-gray-400'>
                {t(props.text)}
              </p>
              <a
                href={t(props.link)}
                target='_blank'
                rel='noopener noreferrer external'
                className='mt-4 block text-[0.9rem] font-medium text-highlight visited:text-highlight hover:text-highlight'
              >
                {t('POPOVER-LINK-TEXT')}
              </a>
            </div>
          </div>
        </div>
      }
    >
      <div
        // key={props.title}
        className={cn('h-full w-full', props.className)}
        // onFocus={() => {
        //   setIsPopupOpen(true);
        //   timer.current = setTimeout(() => {
        //     setIsPopupOpen(false);
        //   }, 10000);
        // }}
        // onBlur={() => {
        //   timer.current = setTimeout(() => {
        //     setIsPopupOpen(false);
        //   }, 1000);
        // }}
        onMouseEnter={() => {
          clearTimeout(timer.current);
          timer.current = setTimeout(() => {
            setIsPopupOpen(true);
          }, props.delay);
        }}
        onMouseLeave={() => {
          clearTimeout(timer.current);
          timer.current = setTimeout(() => {
            setIsPopupOpen(false);
          }, 1000);
        }}
      >
        {props.children}
      </div>
    </Popover>
  );
};

NewPopover.defaultProps = {
  delay: 1000,
};

export default NewPopover;
