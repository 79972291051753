import React from 'react';
import SmallCard from '../../components/Cards/NewCards/SmallCard';
import useTranslate from '../ContextHooks/useTranslate';

interface Props {
  user: any;
  message: string;
  closeToast?: () => {};
}
/**
 * User Notification when some things happens user related
 *
 * @category Components
 * @subcategory Notification
 *
 * @component
 */
const UserNotifier: React.FC<Props> = (props) => {
  const { t } = useTranslate();

  return (
    <div className='min-w-80'>
      <SmallCard
        opacity
        avatar={props.user?.avatar}
        line1={t(props.message)}
        onlyLine1
        link={'/profile/' + props.user?.slug}
        handleClose={() => {
          props.closeToast && props.closeToast();
        }}
      />
    </div>
  );
};

export default React.memo(UserNotifier);
