import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db_log } from '../BackendConnection/Logs';

function Fallback({ error }: { error: Error }) {
  const navigate = useNavigate();
  useEffect(() => {
    const log = async () => {
      if (window.location.origin === 'http://localhost:3000') return;
      if (error.message.startsWith('Loading chunk')) {
        window.location.reload();
        return;
      }
      if (error.message.startsWith('Loading CSS')) {
        window.location.reload();
        return;
      }
      if (error.message.startsWith(`Failed to execute 'removeChild'`)) {
        window.location.reload();
        return;
      }
      if (error.message.startsWith(`Minified React error`)) {
        window.location.reload();
        return;
      }

      db_log({
        identification: 'FallBack Error',
        area: 'Fallback',
        environment: 'frontend',
        level: 'error',
        message: error.message,
      });
      captureException(error);
      navigate('/whatsup');
      window.location.reload();
    };
    log();
  }, [error, error.message, navigate]);

  return <div></div>;
}
export default Fallback;
