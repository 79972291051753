const cloudfront = (src: string, resize?: string, sharpen?: string) => {
  let fall: string = null;
  let lazy: string = null;
  if (src?.startsWith('https://marooze.s3.eu-west-3.amazonaws.com/') && resize) {
    const f = src?.slice('https://marooze.s3.eu-west-3.amazonaws.com/'.length);
    fall =
      'https://media.marooze.com/' +
      `fit-in/${resize}/` +
      (sharpen ? `filters:sharpen(${sharpen})/` : '') +
      f;
    lazy =
      'https://media.marooze.com/' +
      // `fit-in/${resize}/` +
      // (sharpen ? `filters:sharpen(${sharpen})/` : '') +
      'filters:proportion(0.01)/' +
      f;
  } else if (src?.startsWith('https://marooze.s3.eu-west-3.amazonaws.com/')) {
    const f = src?.slice('https://marooze.s3.eu-west-3.amazonaws.com/'.length);
    fall = 'https://media.marooze.com/' + f;
    // lazy =
    //   'https://d237a3nokvmmh9.cloudfront.net/' +
    //   'filters:proportion(0.01)/' +
    //   f;
  } else {
    fall = src;
  }

  return {
    fall,
    lazy,
  };
};
export default cloudfront;
