import { default as io, Socket } from 'socket.io-client';
import { create } from 'zustand';

type Store = {
  action_socket: Socket | null;
  isConnected: () => boolean;
  connect: () => void;
  disconnect: () => void;
};

const useAction = create<Store>()((set, get) => ({
  action_socket: io(window.server + '/activity', {
    withCredentials: true,
    autoConnect: false,
  }),
  isConnected: () => {
    return get().action_socket?.connected || false;
  },
  connect: () => {
    const socket = io(window.server + '/activity', {
      withCredentials: true,
      autoConnect: false,
    });

    socket.on('connect', () => {
      // socket.emit('storeID');
      // setIsConnected(true);
    });
    // socket.io.uri = window.server + '/chat';
    socket.connect();
    set({ action_socket: socket });
  },
  disconnect: () => {
    get().action_socket.disconnect();
  },
}));

// import { useCallback } from 'react';
// import { default as io } from 'socket.io-client';

// const socket = io(window.server + '/action', {
//   withCredentials: true,
//   autoConnect: false,
// });

// socket.on('connect', () => {
//   // socket.emit('storeID');
//   // setIsConnected(true);
// });

// const useAction = () => {
//   const isConnected = useCallback(() => {
//     return socket.connected;
//   }, []);

//   const connect = useCallback(() => {
//     // @ts-ignore
//     socket.io.uri = window.server + '/action';
//     socket.connect();
//   }, []);
//   const disconnect = useCallback(() => {
//     socket.disconnect();
//   }, []);

//   return {
//     action_socket: socket,
//     connect,
//     disconnect,
//     isConnected,
//   };
// };

export default useAction;

export const recive_live_news = 'GLOBAL_LIVE_NEWS';
