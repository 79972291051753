/**
 * Section Header to be used in Pages or in Other Section Only
 */

import React from 'react';

// import { Prompt } from 'react-router-dom';
import NewSectionHeader from '../../new-design/SectionHeader';
interface Props {
  title?: string;
  withTooltip?: boolean;
  children: React.ReactNode;
  editable?: boolean;
  blocking?: boolean;
  noneditable?: boolean;
  className?: string;
  badge?: {
    text: string;
    color: string;
  };

  layoutMode?: boolean;
  layoutChange?: Function;

  workListShow?: boolean;

  handleEditClick?: Function;
  cancelEdit?: Function;

  workListState?: Function;
  handleCopy?: Function;
  handleFolderPlus?: Function;
  handleUploadClick?: Function;
  handleFileEdit?: Function;
  handleTrashClick?: Function;
  handleBoxBallotClick?: Function;
  handleTimes?: Function;

  handleFileAdd?: Function;
  permissionFileAdd?: boolean | null;

  handleFile?: Function;
  handleSave?: Function;
  handleStar?: Function;
  isStar?: boolean;
  handleUserPlus?: Function;
  handlePaperPlane?: Function;
  handlePaperClip?: Function;
  handleArrowRotateLeft?: Function;
  handleCheckSquare?: Function;
  handleFileCSV?: Function;
  handleUndoClick?: Function;
  handleSearchClick?: Function;
  handleUserFriend?: Function;
  handleUserClick?: Function;
  handlePlus?: Function;
  handleLaugh?: Function;
  handleExclamationTriangle?: Function;
  handleCC_Click?: Function;
  handleAddressCard?: Function;

  onClick?: Function;

  handleLanguage?: Function;
  tooltipLanguage?: string;

  workListCopyMoveDelete?: boolean;
  copyWorklist?: Function;
  moveWorklist?: Function;
  trashWorklist?: Function;
}

/**
 *  Section Header to be used in Pages or in Other Section Only
 * @category Section
 * @subcategory Event

 *
 * @component
 */

const SectionHeader: React.FC<Props> = (props) => {
  return <NewSectionHeader {...props} />;
};

SectionHeader.defaultProps = {
  editable: false,
  blocking: true,
};

export default React.memo(SectionHeader);
