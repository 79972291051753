import axios from 'axios';
/**
 * @category Backend Connection
 *  @module Activity
 * */

/**  Gets a list of Activities, with optional pagination and filtering parameters. of curent user */
export const getMyActivity = async ({
  limit = undefined,
  skip = undefined,
  sort = { createdAt: 'descending' },
  find = undefined,
  select = undefined,
}) => {
  const response = await axios.get(window.server + `/api/v1/activity/me`, {
    withCredentials: true,
    params: {
      limit,
      skip,
      sort: JSON.stringify(sort),
      find: JSON.stringify(find),
      select,
    },
  });

  const data = response?.data;
  return data;
};

/**  Gets a list of Activities, with optional pagination and filtering parameters. of curent user */
export const suspisiousActivity = async ({
  limit = undefined,
  skip = undefined,
  sort = { createdAt: 'descending' },
  find = undefined,
  select = undefined,
}) => {
  const response = await axios.get(
    window.server + `/api/v1/activity/suspisious`,
    {
      withCredentials: true,
      params: {
        limit,
        skip,
        sort: JSON.stringify(sort),
        find: JSON.stringify(find),
        select,
      },
    }
  );

  const data = response?.data;
  return data;
};

/**  Gets a list of Activities, with optional pagination and filtering parameters. of curent user */
export const getMyActivityMe = async ({
  limit = undefined,
  skip = undefined,
  sort = { createdAt: 'descending' },
  find = undefined,
  select = undefined,
}) => {
  const response = await axios.get(window.server + `/api/v1/activity/mee`, {
    withCredentials: true,
    params: {
      limit,
      skip,
      sort: JSON.stringify(sort),
      find: JSON.stringify(find),
      select,
    },
  });

  const data = response?.data;
  return data;
};

/** Mark Activity as Read */
export const readActivity = async (id) => {
  const response = await axios.put(
    window.server + `/api/v1/activity/me/${id}`,
    {},
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};

/** Mark Activity as Read */
export const readAllActivity = async () => {
  const response = await axios.put(
    window.server + `/api/v1/activity/me/`,
    {},
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};

/**  Gets a list of Activities, with optional pagination and filtering parameters.*/
export const getAllActivity = async ({
  limit = undefined,
  skip = undefined,
  sort = undefined,
  find = undefined,
  select = undefined,
}) => {
  const response = await axios.get(window.server + `/api/v1/activity/all`, {
    withCredentials: true,
    params: {
      limit,
      skip,
      sort: JSON.stringify(sort),
      find: JSON.stringify(find),
      select,
    },
  });

  const data = response?.data;
  return data;
};
