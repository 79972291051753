import axios from 'axios';
/**
 * @category Backend Connection
 *  @module Util
 * */
/** Load the Count Information */
const loadCounts = async () => {
  const response = await axios.get(window.server + `/api/v1/util/count`, {
    withCredentials: true,
  });

  return response?.data?.data;
};
/** Load the Count Information */
const radarCount = async () => {
  const response = await axios.get(window.server + `/api/v1/util/radar-count`, {
    withCredentials: true,
  });

  return response?.data?.data;
};

export { loadCounts, radarCount };
