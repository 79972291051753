import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '../../../Helpers/cn';

interface Props {
  length: number;
  activePage?: number;
  getValue?: Function;
}
/**
 * Pagination Show to renders page no and show the required data
 *
 * @todo may be Pagination HOC will be great and easy to manage
 *
 * @category Components
 * @subcategory Pagination
 *
 * @component
 */
const PaginationShow: React.FC<Props> = (props) => {
  const { getValue, length } = props;
  const [active, setActive] = useState(props.activePage || 1);
  const [gotoActive, setGotoActive] = useState(false);
  const [_active, _setActive] = useState<number>(props.activePage || 1);

  useEffect(() => {
    if (active > length) {
      if (getValue) {
        getValue(1);
      }
      _setActive(1);
      setActive(1);
    }
  }, [active, getValue, length]);

  const handleClick = useCallback(
    (value: number) => {
      if (props.getValue) {
        props.getValue(value);
      }
      setActive(value);
    },
    [props]
  );

  let pageLimit = props.length;

  if (pageLimit === 0) {
    pageLimit = 1;
  }

  if (props.length === 0) return null;
  return (
    <div className='flex'>
      <ul className='mt-2 flex gap-0 overflow-x-auto'>
        <li className={`overflow-hidden ${active === 1 ? 'disabled' : ''}`}>
          <button
            className='h-7 w-7 rounded-md border border-light_grey bg-secondary hover:bg-[#22ad54]/70 hover:disabled:bg-secondary/70'
            onClick={() => handleClick(active - 1)}
            aria-label='Previous'
            disabled={active === 1}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'chevrons-left', style: 'light' })}
              size='xs'
            />
          </button>
        </li>
        <Page pageLimit={pageLimit} active={active} handleClick={handleClick} />
        <li
          className={`overflow-hidden ${
            active === pageLimit ? 'disabled' : ''
          }`}
        >
          <button
            className='h-7 w-7 rounded-md border  border-light_grey bg-secondary hover:bg-[#22ad54]/70 hover:disabled:bg-secondary/70'
            onClick={() => handleClick(active + 1)}
            aria-label='Next'
            disabled={active === pageLimit}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'chevrons-right', style: 'light' })}
              size='xs'
            />
          </button>
        </li>
      </ul>
      <div className={`ml-1 mt-2 flex gap-0`}>
        <button
          className='h-7 w-7 rounded-md border border-light_grey  bg-secondary px-1 hover:bg-[#22ad54]/70 hover:disabled:bg-secondary/70'
          onClick={() => {
            setGotoActive(true);
          }}
          aria-label='Next'
        >
          <FontAwesomeIcon
            icon={icon({ name: 'arrows-turn-right', style: 'light' })}
            size='xs'
          />
        </button>

        {gotoActive && (
          <div
            className={cn(
              'absolute bottom-full min-w-16 rounded-md border-light_grey  bg-secondary px-1 text-white placeholder-white '
            )}
          >
            <input
              className='w-full bg-transparent p-1 focus:outline-none'
              onChange={(e) => {
                e.target.reportValidity();
                _setActive(parseInt(e.target.value));
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClick(_active);
                  setGotoActive(false);
                }
              }}
              onBlur={() => {
                handleClick(_active);
                setGotoActive(false);
              }}
              min={1}
              max={pageLimit}
              value={_active}
              type='number'
              autoFocus
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(PaginationShow);

const Page = ({ pageLimit, active, handleClick }) => {
  const Page = [];

  let renderOne = false;
  let renderLast = false;
  let renderBefore = false;
  let renderAfter = false;
  let renderPage = [];

  if (pageLimit > 5) {
    if (active >= 4) {
      renderOne = true;
    }
    if (active > 4) {
      renderBefore = true;
    }

    if (active <= pageLimit - 3) {
      renderLast = true;
    }
    if (active < pageLimit - 3) {
      renderAfter = true;
    }
  }

  if (active - 1 <= 0) {
    renderPage = [1, 2, 3, 4, 5];
  } else if (active - 2 <= 0) {
    renderPage = [1, 2, 3, 4, 5];
  } else if (active + 1 > pageLimit) {
    renderPage = [
      pageLimit - 4,
      pageLimit - 3,
      pageLimit - 2,
      pageLimit - 1,
      pageLimit,
    ];
  } else if (active + 2 > pageLimit) {
    renderPage = [
      pageLimit - 4,
      pageLimit - 3,
      pageLimit - 2,
      pageLimit - 1,
      pageLimit,
    ];
  } else {
    renderPage = [active - 1, active, active + 1];
  }

  for (let index = 0; index < pageLimit; index++) {
    if (renderPage.includes(index + 1)) {
      Page.push(index + 1);
    }
  }

  return (
    <>
      {renderOne && (
        <li className='page-item' key={'first'}>
          <button
            className={cn(
              `h-7 w-7 rounded-md border border-light_grey bg-secondary `,
              {
                'bg-[#22ad54]': 1 === active,
                'hover:bg-[#22ad54]/70': 1 !== active,
              }
            )}
            onClick={() => handleClick(1)}
          >
            1
          </button>
        </li>
      )}
      {renderBefore && (
        <li className='page-item disabled'>
          <button
            className={`disabled h-7 w-7 rounded-md border  border-light_grey bg-secondary hover:bg-[#22ad54]/70 hover:disabled:bg-secondary/70`}
            disabled
            style={{ paddingLeft: 5, paddingRight: 5 }}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'ellipsis', style: 'light' })}
            />
          </button>
        </li>
      )}
      {Page.map((i) => {
        return (
          <li className='page-item' key={i}>
            <button
              className={cn(
                `h-7 w-7 rounded-md border border-light_grey bg-secondary `,
                {
                  'bg-[#22ad54]': i === active,
                  'hover:bg-[#22ad54]/70': i !== active,
                }
              )}
              onClick={() => handleClick(i)}
            >
              {i}
            </button>
          </li>
        );
      })}
      {renderAfter && (
        <li className='page-item disabled' key={'after_eclips'}>
          <button
            className={`h-7 w-7 rounded-md border  border-light_grey bg-secondary hover:bg-[#22ad54]/70 hover:disabled:bg-secondary/70`}
            disabled
            style={{ paddingLeft: 5, paddingRight: 5 }}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'ellipsis', style: 'light' })}
            />
          </button>
        </li>
      )}
      {renderLast && (
        <li className='page-item ' key={'last'}>
          <button
            className={cn(
              `h-7 w-7 rounded-md border border-light_grey bg-secondary `,
              {
                'bg-[#22ad54]': pageLimit === active,
                'hover:bg-[#22ad54]/70': pageLimit !== active,
              }
            )}
            onClick={() => {
              handleClick(pageLimit);
            }}
          >
            {pageLimit}
          </button>
        </li>
      )}
    </>
  );
};
