import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import useConstants from './Context/ContextHooks/useConstants';
// import useCount from './Context/ContextHooks/useCount';
import useTranslate from './Context/ContextHooks/useTranslate';
import useUser from './Context/ContextHooks/useUser';

import { Settings } from 'luxon';
import useSidebar from './Context/ContextHooks/useSidebar';
import Routes from './Routes';
import useCount from './Zustand/useCount';
import useCredits from './Zustand/useCredits';
import LanguageOptions from './assets/JSONData/LanguageOptions';
import useUpdater, {
  ConstantsUpdater,
  CountUpdater,
} from './hooks/Sockets/useUpdater';
// new
/**
 *  Starting Point of React Application
 */
const App = () => {
  const { user, authToken: authToken } = useUser();
  const { refresh: refreshConsants } = useConstants();
  const { loadCredits } = useCredits();
  // const { refresh: refreshPermission } = usePermission();
  const { loadFallbackLanguage, loadLanguage } = useTranslate();
  const { loadCounts, loadRadar } = useCount();
  const { connect: updatedConnect, updater_socket } = useUpdater();
  const { setSidebarState, isLarge } = useSidebar();

  useEffect(() => {
    if (isLarge) {
      setSidebarState(false);
    } else {
      setSidebarState(true);
    }
  }, [isLarge, setSidebarState]);

  useEffect(() => {
    updatedConnect();
    authToken();
    refreshConsants();
    loadFallbackLanguage();
  }, [loadFallbackLanguage, refreshConsants, authToken, updatedConnect]);

  useEffect(() => {
    updater_socket.on(ConstantsUpdater, () => {
      refreshConsants();
    });
  }, [refreshConsants, updater_socket]);
  useEffect(() => {
    const _function = () => {
      loadCounts();
    };

    if (user) {
      updater_socket.on(CountUpdater, _function);
    } else {
      updater_socket.off(CountUpdater, _function);
    }
  }, [loadCounts, refreshConsants, updater_socket, user]);

  useEffect(() => {
    // showBoundary(true)
    if (user) {
      // refreshPermission();
      loadCounts();
      loadRadar();
      loadCredits();

      if (user?.settings.date_locale) {
        Settings.defaultLocale = user?.settings.date_locale;
      }
    }
  }, [loadCounts, loadCredits, loadRadar, user]);

  useEffect(() => {
    if (user === undefined) return;

    if (user?.settings?.language) {
      loadLanguage(user?.settings?.language);
    } else {
      const lang = navigator.language.slice(0, 2);

      const LO = LanguageOptions().map((L) => L.value.slice(0, 2));
      const index = LO.findIndex((l) => l === lang);

      if (index !== -1) {
        loadLanguage(LanguageOptions()[index].value);
      } else {
        loadLanguage('en-US');
      }
    }
  }, [loadLanguage, user, user?.settings?.language]);

  return (
    <div className='relative'>
      <RouterProvider router={Routes} />
    </div>
  );
};

export default App;
