import { createRoot } from 'react-dom/client';
// import './assets/FALibrary/FALibrary';
import './assets/scss/Index.scss';
/** Starting Point of React Application */

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import Providers from './Providers';
// just a commit for redeploy

const root = createRoot(document.getElementById('root'));
root.render(<Providers />);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
window.addEventListener('beforeinstallprompt', (e) => {
  window.deferredPrompt = e;
});
