import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import useTranslate from '../../Context/ContextHooks/useTranslate';
import { cn } from '../../Helpers/cn';
import Pagination from '../FormElement/Pagination/Pagination';
import PaginationShow from '../FormElement/Pagination/PaginationShow';
interface Props {
  column: Column<{}>[] | any;
  data: {}[];
  isFilterable?: boolean;
  isSortable?: boolean;
  isPaginate?: boolean;
  filter?: any;
  onClick?: Function;
  pagination?: number[];
  manualSort?: (i: any) => void;
  fullpage?: boolean;
  className?: { tr?: string; td?: string };
  noHeader?: boolean;
}

let filter: any;

/**
 * Table Component that display all kind of tables
 * @category Components
 * @subcategory Individual
 *
 * @component
 */
const GeneralTable: React.FC<Props> = (props) => {
  filter = props.filter;

  return (
    <div className=' border  border-light_grey'>
      <Table
        columns={props.column}
        fullpage={props.fullpage}
        data={props?.data}
        noHeader={props?.noHeader}
        isFilterable={props.isFilterable}
        isSortable={props.isSortable}
        isPaginate={props.isPaginate}
        onClick={props.onClick}
        pagination={props.pagination}
        manualSort={props.manualSort}
        {...props}
      />
    </div>
  );
};

interface TableProps {
  columns: Column<{}>[];
  data: {}[];
  isFilterable?: boolean;
  isSortable?: boolean;
  isPaginate?: boolean;
  filter?: any;
  onClick?: Function;
  pagination?: number[];
  manualSort?: (i: any) => void;
  fullpage?: boolean;
  noHeader?: boolean;
  className?: { tr?: string; td?: string };
}

function DefaultColumnFilter({ column: { setFilter, id } }: any) {
  useEffect(() => {
    if (filter) {
      if (id === 'activity') {
        setFilter(filter.activity);
      } else if (id === 'detail') {
        setFilter(filter.detail);
      } else if (id === 'externalID') {
        setFilter(filter.externalID);
      } else if (id === 'stagename') {
        setFilter(filter.stagename);
      } else if (id === 'title') {
        setFilter(filter.title);
      } else if (id === 'problem') {
        setFilter(filter.problem);
      } else if (id === '_id') {
        setFilter(filter?._id);
      } else if (id === 'text') {
        setFilter(filter.enUS);
      } else if (id === 'other_text') {
        setFilter(
          filter.nonTraslated ? 'empty_table_value_00112233' : undefined
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return <></>;
}

const Table = ({
  columns,
  data,
  isFilterable,
  isPaginate = true,
  isSortable,
  onClick,
  fullpage,
  pagination,
  noHeader,
  className,
  manualSort,
}: TableProps) => {
  const { t } = useTranslate();
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    headers,
    prepareRow,
    page,
    rows,
    ////////////

    pageOptions,
    // pageCount,
    gotoPage,

    setPageSize,
    state: { pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableFilters: !isFilterable,
      disableSortBy: !isSortable,
      autoResetFilters: false,
      manualSortBy: !!manualSort,
    },
    useFilters,
    useSortBy,
    // useBlockLayout,
    isPaginate && usePagination
  );

  useEffect(() => {
    manualSort && manualSort(sortBy);
  }, [manualSort, sortBy]);

  return (
    <>
      <div
        className={cn(' w-full overflow-x-auto text-white  shadow-md ', {
          'max-h-[600px]  overflow-y-auto': !fullpage,
        })}
      >
        <table
          {...getTableProps()}
          className={cn(' w-full  bg-primary text-left text-sm rtl:text-right')}

          // opacity: 0.95;
          // position: sticky;
          // width: 180px;
          // z-index: 1;
          // box-shadow: gray -4px 0px 4px -4px inset;
          // left: 0px;
        >
          {!noHeader && (
            <thead className='sticky top-0  z-20 bg-primary text-xs text-white'>
              <tr>
                {headers.map((column: any, i) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={i}
                    className={cn('py-3 ', {
                      'sticky left-0 z-10 w-48 bg-primary opacity-90':
                        column.pin,
                    })}
                  >
                    <div className='flex items-center px-6'>
                      <span>{t(column.render('Header'))}</span>
                      {column.canFilter && (
                        <div className='filter'> {column.render('Filter')}</div>
                      )}
                      {column.canSort && (
                        <div className='ml-1'>
                          <button
                            onClick={() => column.toggleSortBy()}
                            className={`ml-1 focus:outline-none ${
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'text-red-500'
                                  : 'text-green-500'
                                : 'text-white'
                            }`}
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={icon({ name: 'angle-down' })}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={icon({ name: 'angle-up' })}
                                />
                              )
                            ) : (
                              <FontAwesomeIcon
                                icon={icon({ name: 'arrows-alt-v' })}
                              />
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody className='mt-1'>
            {(isPaginate ? page : rows).map((row: any, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  onClick={() => {
                    onClick && onClick(row.original);
                  }}
                  className={cn(
                    'border-b-[0.5px] border-b-[#515151] bg-secondary hover:bg-secondary/80',
                    className?.tr
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Data
                        className={className?.td}
                        cell={cell}
                        key={cell.row.index + '-' + cell.column.id}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isPaginate && (
        <div className='col-12 d-flex  justify-content-between  text-white'>
          <div />
          <PaginationShow
            length={pageOptions?.length}
            getValue={(i) => {
              gotoPage(i - 1);
            }}
          />
          <Pagination
            Options={pagination || [5, 10, 15, 20]}
            getValue={(e) => setPageSize(Number(e))}
            value={pageSize}
          />
        </div>
      )}
    </>
  );
};

const Data = ({ cell, className }) => {
  const ref = useRef<HTMLDivElement | null>();

  const [d, setD] = useState(null);

  useEffect(() => {
    if (ref.current)
      if (ref.current?.scrollHeight > ref?.current.clientHeight) {
        setD(ref.current.innerText);
      }
  }, [ref.current?.scrollHeight]);

  return (
    <td
      {...cell.getCellProps()}
      className={cn(`px-6 ${cell.column.id}`, className, {
        'sticky left-0 z-10 w-48 bg-secondary opacity-90': cell?.column?.pin,
      })}
    >
      <div
        className='centre-v'
        style={{
          minHeight: '35px',
        }}
      >
        {cell.column.disableClamp ? (
          <div ref={ref} className=''>
            {cell.render('Cell')}
          </div>
        ) : (
          <>
            {cell.column.overflow ? (
              <div ref={ref} className=''>
                {cell.render('Cell')}
              </div>
            ) : (
              <div ref={ref} className='line-clamp-2 flex-grow overflow-hidden'>
                {cell.render('Cell')}
              </div>
            )}
          </>
        )}

        {!cell.column.disableClamp && d && (
          <FontAwesomeIcon
            icon={icon({ name: 'info-circle', style: 'light' })}
            size='xl'
            data-bs-toggle='tooltip'
            title={d}
          />
        )}
      </div>
    </td>
  );
};

GeneralTable.defaultProps = {
  isFilterable: false,
  isSortable: true,
};

export default GeneralTable;
