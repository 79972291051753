import axios from 'axios';

/**  Gets a list of Activities, with optional pagination and filtering parameters. of curent user */
export const recaptha = async (token: string) => {
  const response = await axios.post(
    window.server + `/api/v1/logs/recaptha`,
    { token: token },
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};

/**  Gets a list of Activities, with optional pagination and filtering parameters. of curent user */
export const db_log = async (data1: {
  identification: string;
  level: 'info' | 'success' | 'warn' | 'error';
  message: string;
  area?: string;
  environment: 'frontend';
  email?: string;
  division?: string;
  meta?: any;
}) => {
  const response = await axios.post(
    window.server + `/api/v1/logs/db/new`,
    data1,
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};
