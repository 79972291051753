import { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ApplicationContext } from '../ApplicationContext';

const useTheme = () => {
  const { theme, user } = useContext(ApplicationContext);

  const darkQuery = useMediaQuery({ query: '(prefers-color-scheme: dark)' });
  const { state, setState } = theme;
  const { state: userState } = user;

  useEffect(() => {
    setState('Night');
  }, [userState?.settings?.theme, darkQuery, setState]);

  const isDark = () => {
    return true;
  };

  return { isDark, theme: state };
};

export default useTheme;
