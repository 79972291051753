import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line react/no-deprecated
import { render, unmountComponentAtNode } from 'react-dom';
import Input from '../new-design/Components/FormElements/Input';
import TextArea from '../new-design/Components/FormElements/TextArea';
import ModalHOC from '../new-design/HOCs/ModalHOC';

interface PromptPopupProps {
  label: string;
  confirm?: (x: string) => void;
  input?: {
    input?: React.InputHTMLAttributes<HTMLInputElement>;
    textarea?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  };
}

interface ConfirmPopupProps {
  label: string;
  confirm?: () => void;
  discard?: () => void;
}
interface CustomPopupProps {
  label: string;
  value: React.ReactElement;
  close?: Function;
}
interface CustomFCProps {
  label: string;
  value: React.FunctionComponent;
}
/** @module Hooks */
/**
 * usePopup hook to popup custom Modals
 *
 * @todo to be corrected to use react 18 DOM
 * @todo used all modal with this hooks
 *

 *
 * @function
 */
const usePopup = () => {
  const promptPopup = useCallback(
    (
      label: string,
      confirm?: (x: string) => void,
      input?: {
        input?: React.InputHTMLAttributes<HTMLInputElement>;
        textarea?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
      }
    ) => {
      createPromptPopup({ label: label, confirm, input });
    },
    []
  );
  const confirmPopup = useCallback(
    (label: string, confirm?: () => void, discard?: () => void) => {
      createconfirmPopup({ label: label, confirm, discard });
    },
    []
  );
  const customPopup = useCallback(
    (value: React.ReactElement, label: string) => {
      createCustomPopup({ value, label });
    },
    []
  );
  const customPopupFC = useCallback(
    (value: React.FunctionComponent, label: string) => {
      createFCPopup({ value, label });
    },
    []
  );

  return { confirmPopup, customPopup, customPopupFC, promptPopup };
};

export default usePopup;

const CustomPopup = ({ value: Value, label }: CustomPopupProps) => {
  return (
    <ModalHOC
      cancel={() => {
        removeCustomPopup();
      }}
      confirm={() => {
        removeCustomPopup();
      }}
      hasButton={false}
      ConfirmButtonText=''
      label={label}
      modalstate
      className='max-w-3xl  text-white'
    >
      {Value}
    </ModalHOC>
  );
};
const ConfirmPopup = ({ label, discard, confirm }: ConfirmPopupProps) => {
  return (
    <ModalHOC
      cancel={() => {
        discard && discard();
        RemovePopup();
      }}
      confirm={() => {
        confirm && confirm();
        RemovePopup();
      }}
      hasButton
      // hasCancelButton
      CancelButtonText='No'
      ConfirmButtonText='Yes'
      label={'Confirm'}
      modalstate
      className='max-w-xl  text-center text-white'
    >
      <div className='my-2'> {label}</div>
    </ModalHOC>
  );
};
const PromptPopup = ({ label, confirm, input }: PromptPopupProps) => {
  const [value, setValue] = useState<any>(input?.input?.value || '');

  useEffect(() => {
    if (input?.input?.value) {
      setValue(input?.input?.value);
    } else if (input?.textarea?.value) {
      setValue(input?.textarea?.value);
    }
  }, [input?.input?.value, input?.textarea?.value]);

  return (
    <ModalHOC
      cancel={() => {
        RemovePopup();
      }}
      confirm={() => {
        if (value !== '') {
          confirm && confirm(value);
        }
        RemovePopup();
      }}
      hasButton
      // hasCancelButton
      CancelButtonText='No'
      ConfirmButtonText='Save'
      label={'Prompt'}
      modalstate
      className='max-w-lg text-white'
    >
      <div className='my-2'>
        {input?.textarea ? (
          <TextArea
            textarea={input?.textarea}
            label={label}
            value={value}
            getValue={(e) => {
              setValue(e);
            }}
          />
        ) : (
          <Input
            input={input?.input}
            label={label}
            value={value}
            getValue={(e) => {
              setValue(e);
            }}
          />
        )}
      </div>
    </ModalHOC>
  );
};

function createPromptPopup(properties: PromptPopupProps) {
  let divTarget = document.getElementById('popup');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<PromptPopup {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    divTarget = document.createElement('div');
    divTarget.id = 'popup';
    document.body.appendChild(divTarget);
    render(<PromptPopup {...properties} />, divTarget);
  }
}
function createconfirmPopup(properties: ConfirmPopupProps) {
  let divTarget = document.getElementById('popup');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<ConfirmPopup {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    divTarget = document.createElement('div');
    divTarget.id = 'popup';
    document.body.appendChild(divTarget);
    render(<ConfirmPopup {...properties} />, divTarget);
  }
}

function RemovePopup() {
  const target = document.getElementById('popup');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode && target.parentNode.removeChild(target);
  }
}

function createCustomPopup(properties: CustomPopupProps) {
  let divTarget = document.getElementById('general-popup');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<CustomPopup {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    divTarget = document.createElement('div');
    divTarget.id = 'general-popup';
    document.body.appendChild(divTarget);
    render(<CustomPopup {...properties} />, divTarget);
  }
}

function removeCustomPopup() {
  const target = document.getElementById('general-popup');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode && target.parentNode.removeChild(target);
  }
}

const FCPopup = ({ value: Value, label }: CustomFCProps) => {
  return (
    <ModalHOC
      cancel={() => {
        removeFCPopup();
      }}
      confirm={() => {
        removeFCPopup();
      }}
      hasButton={false}
      ConfirmButtonText=''
      label={label}
      modalstate
      className='max-w-3xl text-white'
    >
      <Value />
    </ModalHOC>
    // <Modal
    //   show
    //   onHide={() => {
    //     removeFCPopup();
    //   }}
    //   size={size}
    //   centered
    //   dialogClassName='custom-modal general-popup'
    // >
    //   <Modal.Body>
    //     <Value />
    //   </Modal.Body>
    // </Modal>
  );
};

function createFCPopup(properties: CustomFCProps) {
  let divTarget = document.getElementById('fc-popup');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<FCPopup {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    divTarget = document.createElement('div');
    divTarget.id = 'fc-popup';
    document.body.appendChild(divTarget);
    render(<FCPopup {...properties} />, divTarget);
  }
}

export function removeFCPopup() {
  const target = document.getElementById('fc-popup');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode && target.parentNode.removeChild(target);
  }
}
