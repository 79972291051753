import React from 'react';
import SmallCard from '../../components/Cards/NewCards/SmallCard';
import useTranslate from '../ContextHooks/useTranslate';

interface Props {
  message: any;
  closeToast?: () => {};
}
/**
 * User Notification when some things happens user related
 *
 * @category Components
 * @subcategory Notification
 *
 * @component
 */
const LiveTickerNotifier: React.FC<Props> = (props) => {
  const { t } = useTranslate();

  return (
    <div className='min-w-80'>
      <SmallCard
        opacity
        avatar={props.message?.avatar}
        line1={props.message?.line1}
        onlyLine1={props.message?.onlyLine1}
        line2={props.message?.line2}
        line2IsDivision={props.message?.line2IsDivision}
        line3={t(props.message?.line3)}
        link={props.message?.link}
        handleClose={() => {
          props.closeToast && props.closeToast();
        }}
      />
    </div>
  );
};

export default React.memo(LiveTickerNotifier);
