import axios from 'axios';
// import { AsyncParser } from '@json2csv/whatwg';
import uniqBy from 'lodash/uniqBy';
/**
 * @category Backend Connection
 *  @module Texts
 * */
/** Load The Selected Language */
const loadLanguage = async (lang) => {
  const response = await axios.get(window.server + `/api/v1/texts/${lang}`, {
    withCredentials: true,
  });

  const texts = uniqBy(response?.data?.data?.texts, '_id');

  return {
    ...response?.data?.data,
    texts: texts,
  };
};
/** Update the Selected Language */
const updateLanguage = async (lang, data1) => {
  const response = await axios.put(
    window.server + `/api/v1/texts/${lang}`,
    data1,
    {
      withCredentials: true,
    }
  );

  return response?.data?.data;
};

/** Update the Selected Language */
const translateServer = async ({ lang, id }: { lang: string; id: string }) => {
  const response = await axios.put(
    window.server + `/api/v1/texts/translate`,
    { lang, id },
    {
      withCredentials: true,
    }
  );

  return response?.data;
};

export { loadLanguage, translateServer, updateLanguage };
