import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import useConstants from '../../../Context/ContextHooks/useConstants';
import useTranslate from '../../../Context/ContextHooks/useTranslate';
import { cn } from '../../../Helpers/cn';
import Image from '../../Image';

interface Props {
  _id?: string;
  online?: boolean;

  blur?: boolean;

  avatar?: string;
  line1?: string;
  line2?: string | { _id: string };
  line3?: string;
  line3clamp?: boolean;
  link?: string;

  onlyLine1?: boolean;
  line2IsDivision?: boolean;

  className?: string;

  selected?: boolean;
  getSelected?: Function;

  isFavorite?: boolean;
  handleFavorite?: () => void;

  opacity?: boolean;

  handleAccept?: () => void;
  handleReject?: () => void;
  handleClose?: () => void;
  handleTrash?: () => void;
  handleBroadcast?: () => void;
  handleProfileAdd?: () => void;
  onClick?: () => void;
}

const SmallCard: React.FC<Props> = (props) => {
  const { t } = useTranslate();
  const { getConstant } = useConstants();
  return (
    <div
      className={cn(
        'relative rounded-xl border border-white/20  bg-[#242424] backdrop-blur-lg ',
        { 'bg-transparent dark:bg-transparent': props.opacity },
        {
          'blur-[.5px]': props.blur,
        },
        props.className
      )}
    >
      <div className='absolute right-1 top-0 '>
        {props.handleReject && (
          <button onClick={props.handleReject} className='p-2'>
            <FontAwesomeIcon
              icon={icon({ name: 'times', style: 'solid' })}
              className={cn('cursor-pointer text-white')}
            />
          </button>
        )}
        {props.handleAccept && (
          <button onClick={props.handleAccept} className='p-2'>
            <FontAwesomeIcon
              icon={icon({ name: 'check', style: 'solid' })}
              className={cn('cursor-pointer text-white')}
            />
          </button>
        )}
        {props.handleFavorite && (
          <button onClick={props.handleFavorite} className='p-2'>
            <FontAwesomeIcon
              icon={
                props.isFavorite
                  ? icon({ name: 'star', style: 'solid' })
                  : icon({ name: 'star', style: 'light' })
              }
              className={cn('cursor-pointer ', {
                'text-white': !props.isFavorite,
                'text-gold': props.isFavorite,
              })}
            />
          </button>
        )}
        {props.handleTrash && (
          <button onClick={props.handleTrash} className='p-2'>
            <FontAwesomeIcon
              icon={icon({ name: 'trash-alt', style: 'light' })}
              className='cursor-pointer text-white'
            />
          </button>
        )}
        {props.handleClose && (
          <button onClick={props.handleClose} className='p-2'>
            <FontAwesomeIcon
              icon={icon({ name: 'times' })}
              className='cursor-pointer text-white'
            />
          </button>
        )}
        {props.handleBroadcast && (
          <button onClick={props.handleBroadcast} className='p-2'>
            <FontAwesomeIcon
              icon={icon({ name: 'broadcast-tower' })}
              className='cursor-pointer text-white'
            />
          </button>
        )}
        {props.handleProfileAdd && (
          <button onClick={props.handleProfileAdd} className='p-2'>
            <FontAwesomeIcon
              icon={icon({ name: 'user-plus' })}
              className='cursor-pointer text-white'
            />
          </button>
        )}
        {props.getSelected && (
          <button
            className='p-2'
            onClick={() => {
              props.getSelected();
            }}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'circle-check' })}
              className={cn('cursor-pointer ', {
                'text-white': !props.selected,
                ' text-green-600': props.selected,
              })}
            />
          </button>
        )}
      </div>
      <CardLink
        onClick={props.onClick}
        link={props.link}
        className=' flex  gap-4  overflow-hidden px-2 py-2'
      >
        {props.avatar && (
          <div>
            <Image
              resize={getConstant('IMAGE-SIZE-SMALL-CARD-AVATAR').value}
              sharpen={
                getConstant('IMAGE-SIZE-SMALL-CARD-AVATAR-SHARPEN').value
              }
              className={cn('max-h-[60px] max-w-[60px] rounded-full', {
                'blur-lg': props.blur,
              })}
              src={props.avatar}
              alt=''
            />
          </div>
        )}
        <hr className='h-12 border-l border-solid border-gray-300 ' />
        <div className='flex flex-col justify-center font-[400] leading-[16px]'>
          {props.line1 && (
            <h1
              className={cn('text-[0.9rem] text-white', {
                'line-clamp-1': !props.onlyLine1,
                'line-clamp-3': props.onlyLine1,
              })}
            >
              {props.line1}
            </h1>
          )}
          {props.line2 && (
            <p className='line-clamp-1 text-[0.9rem] text-gray-300'>
              {props.line2IsDivision ? (
                <>
                  {t(
                    'DIVISION-' +
                      (typeof props.line2 === 'string'
                        ? props.line2
                        : props.line2?._id)
                  )}
                </>
              ) : typeof props.line2 === 'string' ? (
                props.line2
              ) : (
                props.line2?._id
              )}
            </p>
          )}
          {props.line3 && (
            <p
              className={cn('text-[0.9rem] text-gray-300', {
                'line-clamp-1': props.line3clamp,
              })}
            >
              {props.line3}
            </p>
          )}
          <hr className='mt-2 border-gray-300 font-bold ' />
        </div>
      </CardLink>
    </div>
  );
};

SmallCard.defaultProps = {
  line3clamp: true,
};

export default SmallCard;

interface Props2 {
  onClick?: () => void;
  link?: string;
  children: React.ReactNode;
  className?: string;
}

const CardLink: React.FC<Props2> = ({ onClick, link, children, className }) => {
  if (onClick)
    return (
      <button onClick={onClick} className={cn('text-left', className)}>
        {children}
      </button>
    );
  if (link)
    return (
      <Link to={link} className={className}>
        {children}
      </Link>
    );
  else {
    return <div className={className}> {children}</div>;
  }
};
