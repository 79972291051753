import React, { useState } from 'react';
import useTranslate from '../../Context/ContextHooks/useTranslate';

interface Props {
  children: React.ReactNode;

  message?: string;
  top?: boolean;
}

/**
 * HOC For Tooltips
 *
 * @category Components
 * @subcategory HOCs
 *
 * @component
 */

const TooltipHOC: React.FC<Props> = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslate();

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  if (!props.message) return props.children;

  return (
    <span className='relative inline-block'>
      <span
        className=''
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
      >
        {props.children}
      </span>
      <span
        className={` ${showTooltip ? 'opacity-100' : ' hidden opacity-0'} ${
          props.top ? ' -top-full' : ' top-full'
        } absolute left-1/2 z-50 w-max max-w-[100px] -translate-x-1/2  transform rounded-none bg-marooze px-2 py-1 text-white transition-opacity duration-300 dark:bg-marooze-dark md:max-w-xs`}
      >
        {t(props.message)}
      </span>
    </span>
  );
};

export default TooltipHOC;
