import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import usePermission from '../../Context/ContextHooks/usePermission';
import useTheme from '../../Context/ContextHooks/useTheme';
interface Props {
  children: React.ReactNode;
  onNull?: React.ReactNode;
  disable?: boolean;
  popover?: {
    heading: string;
    message: string;
  };
}
/**
 * HOC to grant component premission to Premium User Only
 * equalliant to usePermission.isPremium Hook
 *
 * @category Components
 * @subcategory HOCs
 *
 * @component
 */

const PremiumHOC: React.FC<Props> = (props) => {
  const { theme } = useTheme();
  const { isFreemium, isVisitor } = usePermission();
  if (props.disable) return <>{props.children}</>;
  if (!isFreemium()) return <>{props.children}</>;
  if (isVisitor()) return null;
  if (props.onNull) {
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='auto'
        overlay={popover({
          dark: theme === 'Night',
          popoverMessage: props.popover,
        })}
      >
        <div
          style={{ opacity: 0.7 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {props.onNull}
        </div>
      </OverlayTrigger>
    );
  }
  return null;
};

export default PremiumHOC;

export const popover = ({ dark, popoverMessage }) => (
  <Popover
    id='popover-basic'
    className={`popover premium ${dark ? 'NIGHTMODE' : ''}`}
  >
    {popoverMessage ? (
      <>
        {' '}
        <Popover.Header as='h3'>{popoverMessage.heading}</Popover.Header>
        <Popover.Body>{popoverMessage.message}</Popover.Body>
      </>
    ) : (
      <>
        {' '}
        <Popover.Header as='h3'>Premium Feature</Popover.Header>
        <Popover.Body>
          This Feature is available for Premium user Only
        </Popover.Body>
      </>
    )}
  </Popover>
);
