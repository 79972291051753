import axios from 'axios';

export const loadCreditData = async () => {
  const response = await axios.get(
    window.server + `/api/v1/credits/`,

    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};

export const testCredits = async () => {
  await axios.post(
    window.server + `/api/v1/credits/testCredits`,
    {
      amount: 100,
    },
    {
      withCredentials: true,
    }
  );
};

export const vatcalculate = async (product) => {
  const response = await axios.post(
    window.server + `/api/v1/credits/vat`,
    {
      product: product,
    },
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};
export const subscriptionCost = async (isYearly: boolean) => {
  const response = await axios.post(
    window.server + `/api/v1/credits/subscriptionCost`,
    {
      isYearly: isYearly,
    },
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};
export const saveTaxInfo = async (tax) => {
  const response = await axios.post(
    window.server + `/api/v1/credits/tax`,
    {
      tax: tax,
    },
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};
export const getTaxDocuments = async () => {
  const response = await axios.get(
    window.server + `/api/v1/credits/tax`,

    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};

export const cancelPremium = async () => {
  const response = await axios.post(
    window.server + `/api/v1/credits/premium/cancel`,
    {},
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};
export const cancelcancelationPremium = async () => {
  const response = await axios.post(
    window.server + `/api/v1/credits/premium/revert`,
    {},
    {
      withCredentials: true,
    }
  );

  const data = response?.data;
  return data;
};

export const completePurchase = async (data1) => {
  const response = await axios.post(
    window.server + '/api/v1/credits/completePurchase',
    {
      product: data1,
    },
    {
      withCredentials: true,
    }
  );
  const data = response?.data;
  return data;
};
