import { create } from 'zustand';
import {
  loadCounts as _loadCounts,
  radarCount,
} from '../BackendConnection/Util';

type Store = {
  count: {
    support: number;
    wish: number;
    afflitor: number;
    messages: number;
    wish_user: number;
    report_user: number;
    products: number;
    dashboard: number;
    supervisor: number;
    new_profile: number;
    consumer_profile: number;
    radar: number;
  };
  total: number;

  loadCounts: () => void;
  loadRadar: () => void;
};

const useCount = create<Store>()((set) => ({
  count: {
    support: 0,
    wish: 0,
    afflitor: 0,
    messages: 0,
    products: 0,
    wish_user: 0,
    report_user: 0,
    dashboard: 0,
    supervisor: 0,
    new_profile: 0,
    consumer_profile: 0,
    radar: 0,
  },
  total: 0,
  loadCounts: async () => {
    const counta = await _loadCounts();

    set(({ count }) => {
      const total =
        counta.support +
        counta.wish +
        counta.wish_user +
        counta.report_user +
        counta.afflitor +
        counta.messages +
        counta.dashboard +
        counta.supervisor +
        count.radar;

      return { count: { ...count, ...counta }, total: total };
    });
  },
  loadRadar: async () => {
    const counta = await radarCount();

    set(({ count }) => {
      const total =
        count.support +
        count.wish +
        count.wish_user +
        count.report_user +
        count.afflitor +
        count.messages +
        count.dashboard +
        count.supervisor +
        counta.radar;

      return { count: { ...count, ...counta }, total: total };
    });
  },
}));

export default useCount;
