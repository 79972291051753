import { useCallback, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ApplicationContext } from '../ApplicationContext';

const useSidebar = () => {
  const { sidebar } = useContext(ApplicationContext);

  const isLarge = useMediaQuery({ query: '(min-width: 1023px)' });
  const { state, setState } = sidebar;

  const toggleSidebar = useCallback(() => {
    setState((state) => !state);
  }, [setState]);

  return { sidebar: state,setSidebarState:setState, toggleSidebar, isLarge };
};

export default useSidebar;
