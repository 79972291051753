import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';

import ReactModal from 'react-modal';
import useTranslate from '../../Context/ContextHooks/useTranslate';
import { cn } from '../../Helpers/cn';
ReactModal.setAppElement('#root');
interface Props {
  modalstate: boolean;
  label: string;
  back?: () => void;
  cancel: () => void;
  cancelable?: boolean;
  confirm?: () => void;
  children: ReactNode;
  className?: string;
  hasButton?: boolean;
  buttonDisabled?: boolean;
  hasCancelButton?: boolean;
  ConfirmButtonText?: string;
  confirmbtntype?: 'submit' | 'reset' | 'button';
  CancelButtonText?: string;
  shouldCloseOnOverlayClick?: boolean;
}

/**
 * Add Comment Modal to be Used to Comment on Profiles , Events etcs
 *
 * @todo switch the simple Modal to modal hooks as suggested in react 18
 *
 * @category Components
 * @subcategory Modal
 *
 * @component
 */

const ModalHOC: React.FC<Props> = (props) => {
  const { t } = useTranslate();
  return (
    <ReactModal
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick || false}
      isOpen={props.modalstate}
      onRequestClose={() => {
        props.cancel();
      }}
      overlayClassName='fixed top-0 left-0 z-[1200]  bg-black/70 h-full w-full'
      className={cn(
        'no-scrollbar absolute left-1/2 top-1/2 max-h-[100dvh] w-full max-w-3xl -translate-x-1/2 -translate-y-1/2 overflow-auto  rounded-lg  bg-primary/80  ',
        props.className
      )}
    >
      <div className='relative p-4'>
        <div className='px-2'>
          <h4 className='w-100 modal-title h4 text-center font-light text-white'>
            {t(props.label)}
          </h4>
          {props.cancelable && (
            <FontAwesomeIcon
              icon={icon({ name: 'times', style: 'solid' })}
              onClick={() => {
                props.cancel();
              }}
              size='xl'
              className='absolute right-3 top-3 z-30 cursor-pointer text-white'
            />
          )}
        </div>
        {props.children}
        {props.hasButton && (
          <div className='mt-2 flex justify-center'>
            {props.hasCancelButton && (
              <button
                className='mr-2 inline-flex items-center rounded bg-gray-500 px-6 py-2 font-bold text-white hover:bg-gray-700 disabled:opacity-75'
                disabled={props.buttonDisabled}
                onClick={() => {
                  if (props.back) {
                    props.back();
                  } else {
                    props.cancel();
                  }
                }}
              >
                {t(props.CancelButtonText || 'MODAL-SIGNUP-CANCEL')}
              </button>
            )}
            <button
              className='inline-flex items-center rounded bg-highlight px-6 py-2 font-bold text-white hover:bg-[#388153] hover:duration-300 disabled:opacity-75'
              disabled={props.buttonDisabled}
              type={props.confirmbtntype}
              onClick={() => {
                props.confirm();
              }}
            >
              {t(props.ConfirmButtonText || 'MODAL-SIGNUP-CONTINUE')}
            </button>
          </div>
        )}
      </div>
    </ReactModal>
  );
};

ModalHOC.defaultProps = {
  hasButton: true,
  cancelable: true,
};

export default React.memo(ModalHOC);
