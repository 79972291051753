import { default as io, Socket } from 'socket.io-client';
import { create } from 'zustand';

type Store = {
  updater_socket: Socket | null;
  isConnected: () => boolean;
  connect: () => void;
  disconnect: () => void;
};

const useUpdater = create<Store>()((set, get) => ({
  updater_socket: io(window.server + '/updater', {
    withCredentials: true,
    autoConnect: false,
  }),
  isConnected: () => {
    return get().updater_socket?.connected || false;
  },
  connect: () => {
    const socket = io(window.server + '/updater', {
      withCredentials: true,
      autoConnect: false,
    });

    socket.on('connect', () => {
      // socket.emit('storeID');
      // setIsConnected(true);
    });
    // socket.io.uri = window.server + '/chat';
    socket.connect();
    set({ updater_socket: socket });
  },
  disconnect: () => {
    get().updater_socket.disconnect();
  },
}));

export default useUpdater;

export const ConstantsUpdater = 'CONSTANTS';
export const CountUpdater = 'ADMIN_COUNT';
