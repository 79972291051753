import React, { createContext, useState } from 'react';

export interface PaneProps {
  data: any;
  fullyBooked: boolean;
  likedByMe: boolean;
  observeByMe: boolean;
  isPurchased: boolean;
  isConnected: boolean;
  isConnectionRequest: boolean;
  handleCallSheet: Function;
  handleAttend: Function;
  handleReportPerson: Function;
  handleAddressCardClick: Function;
  handleAddClick: Function;
  handleObserve: Function;
  handleCooperate: Function;
  handleConnect: Function;
  handleThumbsUp: Function;
  handleTrash: Function;
  handlePin: Function;
  handleArrowRightLeft: Function;
  activePin: boolean;
  handleEdit: Function;
  handleCommentsDots: Function;
  handleMessage: Function;
  handleLayout: Function;
  handleShoppingCart: Function;
  handleHand: Function;
  handleBriefcase: Function;
  handleUserClick: Function;
  handleDowload: Function;
  handleLink: Function;
  type:
    | 'MESSAGE'
    | 'PROFILE'
    | 'EVENT'
    | 'PROJECT'
    | 'JOB'
    | 'WHATSUP'
    | 'PRODUCT'
    | 'WORKLIST'
    | 'MOODBOARD'
    | 'CHANGELOG'
    | 'LOCATION'
    | 'EXPLORE'
    | 'PLAYLIST'
    | 'COMMUNITY';

  active: boolean;
  handleActive: Function;

  bullhorn: boolean;
  handleBullHorn: Function;

  activeCodeGear: boolean;
  handleCodeGear: Function;

  handleCrown: Function;
  activeCrown: boolean;

  handleBallot: Function;

  private: boolean;
  handlePrivate: Function;

  nodropdown: boolean;
}

const ApplicationContext = createContext<{
  constants: {
    state: {
      _id: string;
      name: string;
      desc: string;
      type: string;
      category: string;
      updatedAt: string;
      value: any;
    }[];
    setState?: React.Dispatch<React.SetStateAction<any[]>>;
    // refresh?: () => Promise<void>;
  };
  user: {
    state: any;
    setState?: React.Dispatch<React.SetStateAction<any>>;
  };
  adminPrivilege: {
    state: boolean;
    setState?: React.Dispatch<React.SetStateAction<boolean>>;
  };
  message: {
    state: string | null;
    setState?: React.Dispatch<React.SetStateAction<string | null>>;
  };
  other_user: {
    state: string | null;
    setState?: React.Dispatch<React.SetStateAction<any>>;
  };
  sidebar: {
    state: boolean;
    setState?: React.Dispatch<React.SetStateAction<boolean>>;
  };
  tip: {
    state: string | null;
    setState?: React.Dispatch<React.SetStateAction<string | null>>;
  };

  theme: {
    state: string;
    setState?: React.Dispatch<React.SetStateAction<string>>;
  };
  permission: {
    state: any[] | null;
    setState?: React.Dispatch<React.SetStateAction<any[]>>;
  };
  search: {
    state: any[] | null;
    setState?: React.Dispatch<React.SetStateAction<any[]>>;
  };
  pane: {
    state: Partial<PaneProps> | null;
    setState?: React.Dispatch<React.SetStateAction<Partial<PaneProps> | null>>;
  };
  language: {
    state: { _id: string; texts: any[] };
    setState?: React.Dispatch<
      React.SetStateAction<{
        _id: string;
        texts: any[];
      }>
    >;
  };
  fallback_language: {
    state: { _id: string; texts: any[] };
    setState?: React.Dispatch<
      React.SetStateAction<{
        _id: string;
        texts: any[];
      }>
    >;
  };
  credits: {
    state: { locked: number; open: number; paid: number };
    setState?: React.Dispatch<
      React.SetStateAction<{ locked: number; open: number; paid: number }>
    >;
  };
  moodboard: {
    state: string[];
    setState?: React.Dispatch<React.SetStateAction<string[]>>;
  };

  count: {
    state: {
      dashboard: number;
      messages: number;
      projects: number;
      support: number;
      wish: number;
      worklists: number;
      notification: number;
      supervisor: number;
    };
    setState?: React.Dispatch<
      React.SetStateAction<{
        dashboard: number;
        messages: number;
        projects: number;
        support: number;
        wish: number;
        worklists: number;
        notification: number;
        supervisor: number;
      }>
    >;
  };
}>({
  constants: {
    state: [],
  },
  moodboard: {
    state: [],
  },
  message: {
    state: null,
  },
  adminPrivilege: {
    state: false,
  },
  user: {
    state: undefined,
  },
  other_user: {
    state: null,
  },
  pane: {
    state: null,
  },
  credits: {
    state: {
      locked: 0,
      open: 0,
      paid: 0,
    },
  },
  count: {
    state: {
      dashboard: 0,
      messages: 0,
      projects: 0,
      support: 0,
      wish: 0,
      worklists: 0,
      notification: 0,
      supervisor: 0,
    },
  },
  sidebar: {
    state: true,
  },
  theme: {
    state: 'System',
  },
  tip: {
    state: null,
  },

  permission: {
    state: null,
  },
  language: {
    state: { _id: '', texts: [] },
  },
  fallback_language: {
    state: { _id: '', texts: [] },
  },
  search: {
    state: [],
  },
});

const ApplicationProvider = ({ children }) => {
  const [constants, setConstants] = useState([]);
  const [message, setMessage] = useState(null);
  const [tips, setTips] = useState<string | null>(null);
  const [search, setSearch] = useState([]);
  const [permission, setPermission] = useState(null);
  const [user, setUser] = useState(undefined);
  const [other_user, setOther_user] = useState(null);
  const [adminPrivilege, setAdminPrivilege] = useState(false);
  const [_theme, setTheme] = useState('System');
  const [sidebar, setSidebar] = useState(true);
  const [pane, setPane] = useState<PaneProps | null>(null);
  const [language, setlanguage] = useState({ _id: '', texts: [] });
  const [moodboard, setMoodboard] = useState<string[]>([]);
  const [fallback_language, setfallback_language] = useState({
    _id: '',
    texts: [],
  });

  const [credits, setCredits] = useState({
    locked: 0,
    open: 0,
    paid: 0,
  });

  const [count, setCount] = useState({
    dashboard: 0,
    messages: 0,
    projects: 0,
    support: 0,
    wish: 0,
    worklists: 0,
    notification: 0,
    supervisor: 0,
  });

  return (
    <ApplicationContext.Provider
      value={{
        constants: {
          state: constants,
          setState: setConstants,
        },
        credits: {
          state: credits,
          setState: setCredits,
        },
        moodboard: {
          state: moodboard,
          setState: setMoodboard,
        },
        pane: {
          state: pane,
          setState: setPane,
        },
        tip: {
          state: tips,
          setState: setTips,
        },

        message: {
          state: message,
          setState: setMessage,
        },
        adminPrivilege: {
          state: adminPrivilege,
          setState: setAdminPrivilege,
        },
        other_user: {
          state: other_user,
          setState: setOther_user,
        },
        count: {
          state: count,
          setState: setCount,
        },
        search: {
          state: search,
          setState: setSearch,
        },
        sidebar: { state: sidebar, setState: setSidebar },
        theme: { state: _theme, setState: setTheme },
        user: { state: user, setState: setUser },
        permission: { state: permission, setState: setPermission },
        language: { state: language, setState: setlanguage },
        fallback_language: {
          state: fallback_language,
          setState: setfallback_language,
        },
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export { ApplicationContext, ApplicationProvider };
