import { useCallback } from 'react';
import { Id, ToastOptions, toast } from 'react-toastify';
import ComponentNotifier from './Notification/ComponentNotifier';
import GeneralNotifier from './Notification/GeneralNotifier';
import LiveTickerNotifier from './Notification/LiveTickerNotifier';
import ProblemNotifier from './Notification/ProblemNotifier';
import UserNotifier from './Notification/UserNotifier';

export function useToast() {
  const addToast = useCallback(
    (notify: { [x: string]: any; options?: ToastOptions }) => {
      switch (notify.toast) {
        case 'ACTIVITY':
          return toast(
            <ComponentNotifier activity={notify?.data?.message} />,
            notify.options
          );
        case 'USER':
          return toast(
            <UserNotifier
              message={notify?.data?.message}
              user={notify?.data?.user}
            />,
            notify.options
          );
        case 'LIVE_NEWS':
          return toast(
            <LiveTickerNotifier message={notify?.data} />,
            notify.options
          );

        case 'GENERAL':
          return toast(
            <GeneralNotifier message={notify?.data?.message} />,
            notify.options
          );
        case 'PROBLEM':
          return toast(
            <ProblemNotifier
              message={notify?.data?.message}
              type={notify?.data?.type}
            />,
            notify.options
          );
      }
    },
    []
  );
  const updateToast = useCallback(
    (notify: { [x: string]: any; options?: ToastOptions }, id: Id) => {
      switch (notify.toast) {
        case 'ACTIVITY':
          toast.update(id, {
            ...notify.options,
            render: <ComponentNotifier activity={notify?.data?.message} />,
          });
          break;
        case 'USER':
          toast.update(id, {
            ...notify.options,
            render: (
              <UserNotifier
                message={notify?.data?.message}
                user={notify?.data?.user}
              />
            ),
          });
          break;
        case 'LIVE_NEWS':
          toast.update(id, {
            ...notify.options,
            render: (
              <UserNotifier
                message={notify?.data?.message}
                user={notify?.data?.user}
              />
            ),
          });
          break;
        case 'GENERAL':
          toast.update(id, {
            ...notify.options,
            render: <GeneralNotifier message={notify?.data?.message} />,
          });
          break;
        case 'PROBLEM':
          toast.update(id, {
            ...notify.options,
            render: (
              <ProblemNotifier
                message={notify?.data?.message}
                type={notify?.data?.type}
              />
            ),
          });
          break;
      }
    },
    []
  );
  const removeToast = useCallback((id: Id) => {
    toast.dismiss(id);
  }, []);

  return { addToast, removeToast, updateToast };
}
