import { default as io, Socket } from 'socket.io-client';
import { create } from 'zustand';

type Store = {
  community_socket: Socket | null;
  isConnected: () => boolean;
  connect: () => void;
  disconnect: () => void;
};

const useCommunity = create<Store>()((set, get) => ({
  community_socket: io(window.server + '/community', {
    withCredentials: true,
    autoConnect: false,
  }),
  isConnected: () => {
    return get().community_socket?.connected || false;
  },
  connect: () => {
    const socket = io(window.server + '/community', {
      withCredentials: true,
      autoConnect: false,
    });

    socket.on('connect', () => {
      socket.emit('storeID');
      // socket.emit('storeID');
      // setIsConnected(true);
    });
    // socket.io.uri = window.server + '/chat';
    socket.connect();
    set({ community_socket: socket });
  },
  disconnect: () => {
    get().community_socket.disconnect();
  },
}));

// import { useCallback } from 'react';
// import { default as io } from 'socket.io-client';

// const socket = io(window.server + '/community', {
//   withCredentials: true,
//   autoConnect: false,
// });

// socket.on('connect', () => {
//   socket.emit('storeID');
//   // setIsConnected(true);
// });

// const useCommunity = () => {
//   const isConnected = useCallback(() => {
//     return socket.connected;
//   }, []);

//   const connect = useCallback(() => {
//     // @ts-ignore
//     socket.io.uri = window.server + '/community';
//     socket.connect();
//   }, []);
//   const disconnect = useCallback(() => {
//     socket.disconnect();
//   }, []);

//   return {
//     community_socket: socket,
//     connect,
//     disconnect,

//     isConnected,
//   };
// };

export default useCommunity;

export const sendComunityMessage = 'message.send';
export const sendComunityRead = 'message.read';

export const deleteComunityMessage = 'message.delete';
export const deleteComunityMessageRecieve = 'message.delete.recieve';

export const communityChange = 'community.change';
