// eslint-disable-next-line no-unused-vars
const LanguageOptions = () => {
  return [
    {
      display: 'English',
      iso2: 'EN',
      value: 'en-US',
    },
    {
      display: 'German',
      iso2: 'DE',
      value: 'de-DE',
    },
    {
      display: 'French',
      iso2: 'FR',
      value: 'fr-FR',
    },
    {
      display: 'Italian',
      iso2: 'IT',
      value: 'it-IT',
    },
    {
      display: 'Russian',
      iso2: 'RU',
      value: 'ru-RU',
    },
    // {
    //   display: 'Czech',
    //   iso2: 'CS',
    //   value: 'cs-CZ',
    // },
    // {
    //   display: 'Swedish',
    //   iso2: 'SV',
    //   value: 'sv-SE',
    // },
    {
      display: 'Spanish',
      iso2: 'ES',
      value: 'es-ES',
    },
    // {
    //   display: 'Polish',
    //   iso2: 'PL',
    //   value: 'pl-PL',
    // },

    // {
    //   display: 'Hungarian',
    //   iso2: 'HU',
    //   value: 'hu-HU',
    // },
    // {
    //   display: 'Danish',
    //   iso2: 'DA',
    //   value: 'da-DK',
    // },
    // {
    //   display: 'Greek',
    //   iso2: 'EL',
    //   value: 'el-GR',
    // },
    // {
    //   display: 'Turkish',
    //   iso2: 'TR',
    //   value: 'tr-TR',
    // },
    // {
    //   display: 'Chinese',
    //   iso2: 'ZH',
    //   value: 'zh-CHT',
    // },
    // {
    //   display: 'Portuguese',
    //   iso2: 'PT',
    //   value: 'pt-PT',
    // },
    // {
    //   display: 'Japanese',
    //   iso2: 'JA',
    //   value: 'ja-JP',
    // },
    {
      display: 'Dutch',
      iso2: 'NL',
      value: 'nl-NL',
    },
    {
      display: 'Ukrainian',
      iso2: 'UA',
      value: 'uk-UA',
    },
  ];
};

export default LanguageOptions;
